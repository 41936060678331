import React, { Component } from 'react';
import './style.css';
import { isAuth, signout } from '../../helpers/auth';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import Navbar from './../../components/Navbar/index';
import Retailersummary from './../../components/Retailersummary/index';
import Retailerproductsummary from './../../components/Retailerproductsummary/index';
import Footer from './../../components/Footer/index';
import CardSetupForm from './../../components/Cardsetupform/index';
import Modal from 'react-bootstrap/Modal'

class Home extends Component {
    constructor() {
        super()
        this.state = {
            first_name: '',
            email: '',
            errorAlert: '',
            retailers: [],
            isRetailer: '',
            selectedRetailerId: '',
            selectedRetailerProductId: '',
            selectedLogo: '',
            selectedImage: '',
            modal: false,
            hasRetailerProducts: '',
            retailerProducts: [],
            selectedProductRetailer: [],
            allRetailerIds: []
        }
    }

    componentDidMount() {
        if (localStorage.getItem('user')) {
            const user = localStorage.getItem('user');
            const first_name = JSON.parse(user).first_name;
            const email = JSON.parse(user).email;
            this.setState({
                first_name: first_name,
                email: email,
            });

            // Grabs the retailers associated with the logged in user and displays them in the retailers dashboard section
            axios.post('/selfserve/api/retailer/related/find/all', {
                email
            })
                .then(res => {
                    console.log(res);
                    let retailerIds = [];
                    for (let i = 0; i < res.data.retailers.length; i++) {
                        retailerIds.push(res.data.retailers[i].retailer.id)
                    }
                    this.setState({
                        retailerProducts: res.data.packagedRetailerProducts,
                        isRetailer: res.data.isRetailer,
                        retailers: res.data.retailers,
                        hasRetailerProducts: res.data.hasRetailerProducts,
                        allRetailerIds: retailerIds,
                        selectedProductRetailer: retailerIds
                    })
                })
                .catch(err => {
                    console.log(err)
                    this.setState({ errorAlert: err.response.data.errors })
                })  
        }
    }

    render() {
        const { email, first_name, isRetailer, retailers, selectedRetailerId, selectedLogo, modal, hasRetailerProducts, retailerProducts, selectedProductRetailer, selectedRetailerProductId, allRetailerIds } = this.state

        // Handles removing error text from alert onclick "x"
        const handleErrorAlert = e => {
            e.preventDefault()
            this.setState({ errorAlert: '' });
        }

        // Handle change from inputs
        const handleChange = e => {
            this.setState({ [e.target.name]: e.target.value })
        }

        // Handles when user clicks on either the remove or delete buttons in the retailer summary
        // Grabs the specific id of the selected retailer and stores it in state to use in the below functions
        const handleSelectRetailer = (id, logo) => {
            this.setState({
                selectedRetailerId: id,
                selectedLogo: logo
            })
        }

        const handleSelectRetailerProduct = id => {
            this.setState({
                selectedRetailerProductId: id
            })
        }

        // handles deleting the retailer. Deleted the retailer and partner account retailers association records as well
        // This will fire when user clicks continue button in the respective deleteModal
        const handleDeleteRetailer = e => {
            e.preventDefault()
            let id = selectedRetailerId;
            let Retailer_Logo = selectedLogo;
            axios.post('/selfserve/api/retailer/delete', {
                id, Retailer_Logo
            })
                .then(res => {
                    // We need to reload the page because of react. There might be a better more react-y way to do this
                    window.location.reload()
                })
                .catch(err => {
                    console.log(err)
                    this.setState({ errorAlert: err.response.data.errors })
                })
        }

        // Handles removing the retailer from association with this user (deletes the partner_account_retailers record)
        // This will fire when user clicks continue button in the respective removeModal
        const handleRemoveRetailer = e => {
            e.preventDefault()
            let id = selectedRetailerId;
            axios.post('/selfserve/api/retailer/remove', {
                id, email
            })
                .then(res => {
                    window.location.reload()
                })
                .catch(err => {
                    console.log(err)
                    this.setState({ errorAlert: err.response.data.errors })
                })
        }

        // handles deleting the retailer product. Deletes the image and variant associated records as well
        // This will fire when user clicks continue button in the respective deleteRetailerProductModal
        const handleDeleteRetailerProduct = e => {
            e.preventDefault()
            let id = selectedRetailerProductId;
            axios.post('/selfserve/api/product/delete', {
                id
            })
                .then(res => {
                    // We need to reload the page because of react. There might be a better more react-y way to do this
                    window.location.reload()
                })
                .catch(err => {
                    console.log(err)
                    this.setState({ errorAlert: err.response.data.errors })
                })
        }

        // Handles sending the user to edit the retailer page based on the specific retailer they selected
        const handleEditRetailer = id => {
            this.props.history.push(`/retailer/edit/${id}`)
        }

        // Handles sending the user to edit the product page based on the specific product they selected
        const handleEditRetailerProduct = id => {
            this.props.history.push(`/product/edit/${id}`)
        }

        // Handles sending user to choose retailer page to add a retailer when the user does not have any retailers
        const handleChooseRetailer = e => {
            e.preventDefault()
            this.props.history.push('/choose-retailer')
        }

        // This handles the signout process. Check out the auth.js helper to learn more about how signout works.
        // This just fires the signout function and sne the user to the login page
        const handleSignOut = e => {
            signout(() => {
                this.props.history.push('/login');
            })
        }

        const handleShow = e => {
            this.setState({ modal: true })
        }

        const handleClose = e => {
            this.setState({ modal: false })
        }

        const handleUploadProducts = e => {
            handleClose()
            this.props.history.push('/products/upload');
        }

        // This filters the retailers list based on what the user is inputting into the search field. this is the filtering functionality
        const filteredRetailerProducts = retailerProducts.filter(retailerProduct => {
            return selectedProductRetailer.includes(retailerProduct.retailer_id);
        });

        return (
            <div>
                {isAuth() ? null : <Redirect to='/login' />}
                <Navbar handleSignOut={handleSignOut}></Navbar>
                <div className="container">

                    {/* This is for any alerts/errors */}
                    {(this.state.errorAlert) ?
                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                            {this.state.errorAlert}
                            <button type="button" className="close" onClick={handleErrorAlert} data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        : null}

                    {/*The Delete Retailer modal */}
                    <div>
                        <div className="modal fade" id="deleteModal" role="dialog">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title">Are You Sure?</h4>
                                        <button data-dismiss="modal" aria-label="Close" type="button" className="close"><span aria-hidden="true">&times;</span></button>
                                    </div>
                                    <div className="modal-body">
                                        <p>Are you sure you would like to delete this retailer?</p>
                                        <small>This action is permanent. All other accounts which are associated with this retailer will lose their association with this retailer as well.</small>
                                    </div>
                                    <div className="modal-footer">
                                        <button data-dismiss="modal" aria-label="Close" type="button" className="canceling-button">Cancel</button>
                                        <button data-dismiss="modal" aria-label="Close" type="button" className="submit-button" onClick={handleDeleteRetailer}>Continue</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*The Delete Retailer Product modal */}
                    <div>
                        <div className="modal fade" id="deleteRetailerProductModal" role="dialog">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title">Are You Sure?</h4>
                                        <button data-dismiss="modal" aria-label="Close" type="button" className="close"><span aria-hidden="true">&times;</span></button>
                                    </div>
                                    <div className="modal-body">
                                        <p>Are you sure you would like to delete this product?</p>
                                        <small>This action is permanent. All other accounts which are associated with this product will lose their association with this product as well.</small>
                                    </div>
                                    <div className="modal-footer">
                                        <button data-dismiss="modal" aria-label="Close" type="button" className="canceling-button">Cancel</button>
                                        <button data-dismiss="modal" aria-label="Close" type="button" className="submit-button" onClick={handleDeleteRetailerProduct}>Continue</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*The Remove modal */}
                    <div>
                        <div className="modal fade" id="removeModal" role="dialog">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title">Are You Sure?</h4>
                                        <button data-dismiss="modal" aria-label="Close" type="button" className="close"><span aria-hidden="true">&times;</span></button>
                                    </div>
                                    <div className="modal-body">
                                        <p>Are you sure you would like to remove this retailer?</p>
                                    </div>
                                    <div className="modal-footer">
                                        <button data-dismiss="modal" aria-label="Close" type="button" className="canceling-button">Cancel</button>
                                        <button data-dismiss="modal" aria-label="Close" type="button" className="submit-button" onClick={handleRemoveRetailer}>Continue</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*The Add Product Setup modal */}
                    <div>
                        <div className="modal fade" id="addProductSetupModal" role="dialog">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title">Add Product Setup</h4>
                                        <button data-dismiss="modal" aria-label="Close" type="button" className="close"><span aria-hidden="true">&times;</span></button>
                                    </div>
                                    <div className="modal-body text-center">
                                        <p>In order to continue, you must input your credit card information</p>
                                        <div className="tool-tip">
                                            <p className="underline">Why?</p>
                                            <span className="tool-tip-text">We use this card to charge a standard referral fee on each product ordered by a BWi user. The standard referral fee is 15% on each unit that is ordered through our platform.</span>
                                        </div>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row">
                                            <div className="col-5">
                                                <p>Not ready yet?</p>
                                                <a href="mailto:sales@britswine.com?Subject=Brits%20Wine%20Talk%20To%20Sales%20Person"><small>Talk to a sales person</small></a>
                                            </div>
                                            <div className="col text-right">
                                                <p>By clicking continue, you agree to the <a href="https://www.britswine.com/terms-of-service.html">terms of service</a></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button data-dismiss="modal" className="submit-button" data-toggle="modal" onClick={handleShow} data-target="#addCreditCardInfoModal">Continue</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*The Credit Card Info modal */}
                    <Modal show={modal} onHide={handleClose} centered={true}>
                        <Modal.Header closeButton>
                            <h4>Add Credit Card Details</h4>
                        </Modal.Header>
                        <Modal.Body>
                            <CardSetupForm
                                handleUploadProducts={handleUploadProducts}
                                handleClose={handleClose}
                            />
                        </Modal.Body>
                    </Modal>

                    {/* Title with name */}
                    <div className="row">
                        <div className="col-lg-3">
                            <h4>Your Dashboard</h4>
                            <p>Hi, {first_name}</p>
                        </div>
                        <div className="col-lg-9"></div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6">
                            {/* Retailer dashboard section */}
                            <div className="row dashboard-section retailer-section">
                                {/* Maps the list of associated retailers */}
                                {isRetailer ?
                                    <div>
                                        {retailers.map(retailer => (
                                            <Retailersummary
                                                id={retailer.retailer.id}
                                                key={retailer.retailer.id}
                                                logo={retailer.retailer.logo}
                                                name={retailer.retailer.name}
                                                sales_email={retailer.retailer.sales_email}
                                                other_pos={retailer.retailer.other_pos}
                                                owner={retailer.owner}
                                                handleEditRetailer={handleEditRetailer}
                                                handleSelectRetailer={handleSelectRetailer}
                                            />
                                        ))}
                                    </div>
                                    :
                                    <div className="text-center">
                                        {/* if there are no retailers */}
                                        <h4 className="mt-2">No Retailer Added</h4>
                                        <h6 className="mt-2">Adding a retailer allows you to enjoy the many features that Brit's Wine has to offer</h6>
                                        <button className="submit-button mt-2" onClick={handleChooseRetailer}>Add Retailer</button>
                                    </div>
                                }
                            </div>

                            {/* Retailer Wines Section */}
                            <div className="row dashboard-section retailer-wines-section mt-4">
                                <div className="col-lg">
                                    <div>
                                        {hasRetailerProducts ?
                                            <div>
                                                <div className="row mb-2">
                                                    <div className="col-6">
                                                        <select className="custom-select" onChange={handleChange} name="selectedProductRetailer">
                                                            <option value={`${allRetailerIds}`}>Select all...</option>
                                                            {retailers.map(retailer => (
                                                                <option key={`${retailer.retailer.id}`} value={`${retailer.retailer.id}`}>{`${retailer.retailer.name}`}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="col-2"></div>
                                                    <div className="col-4">
                                                        <button className="submit-button" onClick={handleUploadProducts}>Add Products</button>
                                                    </div>
                                                </div>
                                                <div className="row retailer-section">
                                                    <div className="col">
                                                        {/* Maps the list of associated retailer products */}
                                                        {selectedProductRetailer ?
                                                            <div>
                                                                {filteredRetailerProducts.map(retailerProduct => (
                                                                    <Retailerproductsummary
                                                                        id={retailerProduct.id}
                                                                        key={retailerProduct.id}
                                                                        image={retailerProduct.image_url}
                                                                        title={retailerProduct.title}
                                                                        description={retailerProduct.descr}
                                                                        price={retailerProduct.price}
                                                                        inventory_quantity={retailerProduct.inventory_quantity}
                                                                        owner={retailerProduct.owner}
                                                                        product_type={retailerProduct.product_type}
                                                                        handleEditRetailerProduct={handleEditRetailerProduct}
                                                                        handleSelectRetailerProduct={handleSelectRetailerProduct}
                                                                    />
                                                                ))}
                                                            </div>
                                                            :
                                                            <div>
                                                                {retailerProducts.map(retailerProduct => (
                                                                    <Retailerproductsummary
                                                                        id={retailerProduct.id}
                                                                        key={retailerProduct.id}
                                                                        image={retailerProduct.image_url}
                                                                        title={retailerProduct.title}
                                                                        description={retailerProduct.descr}
                                                                        price={retailerProduct.price}
                                                                        inventory_quantity={retailerProduct.inventory_quantity}
                                                                        owner={retailerProduct.owner}
                                                                        product_type={retailerProduct.product_type}
                                                                        handleEditRetailerProduct={handleEditRetailerProduct}
                                                                        handleSelectRetailerProduct={handleSelectRetailerProduct}
                                                                    />
                                                                ))}
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="text-center">
                                                <img src="./../../images/Retailer-Wines-Icon.png" alt="Retailer Wines Icon" width="100px" />
                                                <br></br>
                                                <button className="submit-button mt-4" data-toggle="modal" data-target="#addProductSetupModal">Add Product</button>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            {/* Events Section */}
                            <div className="row dashboard-section events-section">
                                <div className="col-lg text-center events-section-content">
                                    <img src="./../../images/Events-Icon.png" alt="Events Icon" width="100px" />
                                    <h4>Create Events</h4>
                                    <h6>If you want to create an event, <a href="mailto:sales@britswine.com?Subject=Brits%20Wine%20Create%20Event%20Request">contact us!</a></h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer></Footer>
            </div>
        )
    }
}

export default Home;