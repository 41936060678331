import React from "react";
import "./style.css";

function Retailerproductsummary(props) {
    return (
        <div className="retailer-product-info row mb-3" key={props.id}>
            <div className="col-lg-4 justify-content-center">
                <img src={props.image} alt="Retailer Product" />
            </div>
            <div className="col-lg-8">
                <div className="row">
                    <div className="col">
                        <strong>{props.title} </strong>{props.product_type}
                        <p className="description-text">{props.description}</p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-5">
                        <p>Price: ${props.price}</p>
                    </div>
                    <div className="col-7">
                        <p>Quantity: {props.inventory_quantity}</p>
                    </div>
                </div>

                {props.owner ?
                    <div className="row mt-2">
                        <div className="col-6"></div>
                        <div className="col-6">
                            <button className="edit mr-4" onClick={() => props.handleEditRetailerProduct(props.id)}>Edit</button>
                            <button className="remove" data-toggle="modal" data-target="#deleteRetailerProductModal" onClick={() => props.handleSelectRetailerProduct(props.id)}>Delete</button>
                        </div>
                    </div>
                    :
                    null
                }
            </div>
        </div>
    );
}

export default Retailerproductsummary;