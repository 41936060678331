import React, { Component } from 'react';
import axios from 'axios';

class Forgot extends Component {
    constructor() {
        super()
        this.state = {
            email: '',
            successAlert: '',
            errorAlert: ''
        }
    }
    render() {
        const { email } = this.state

        // Handle change from inputs
        const handleChange = e => {
            this.setState({ [e.target.name]: e.target.value })
        }

        // Deletes the success alerts onclick "x"
        const handleSuccessAlert = e => {
            e.preventDefault()
            this.setState({ successAlert: '' });
        }

        // Deletes the error alerts onclick "x"
        const handleErrorAlert = e => {
            e.preventDefault()
            this.setState({ errorAlert: '' });
        }

        // Submit data to backend
        const handleSubmit = e => {
            e.preventDefault()
            if (email) {
                axios.put('/selfserve/api/auth/password/forgot', {
                    email
                })
                    .then(res => {
                        this.setState({
                            email: '',
                            successAlert: res.data.message,
                            errorAlert: ''
                        });
                    })
                    .catch(err => {
                        console.log(err)
                        this.setState({ errorAlert: err.response.data.errors });
                    });
            } else {
                this.setState({ errorAlert: "Please fill all fields" });
            }
        }
        return (
            <div className="container">

                {/* This is for success messages */}
                {(this.state.successAlert) ?
                    <div className="alert alert-success alert-dismissible fade show" role="alert">
                        {this.state.successAlert}
                        <button type="button" className="close" onClick={handleSuccessAlert} data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    : null}

                {/* This is for any alerts/errors */}
                {(this.state.errorAlert) ?
                    <div className="alert alert-danger alert-dismissible fade show" role="alert">
                        {this.state.errorAlert}
                        <button type="button" className="close" onClick={handleErrorAlert} data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    : null}

                <div className="row">
                    <div className="col-lg-3 col-md-1"></div>
                    <div className="col-lg-6 col-md-10">
                        <div className="register-form mt-5 p-5">
                            {/* Title section w/ logo */}
                            <div className="row">
                                <div className="col text-center">
                                    <img src="./../../images/Brits-Wine-Logo-100w.png" alt="Brits Wine logo" width="50px" className="mt-3 mb-3" />
                                    <h4 className="mb-3">
                                        Forgot Password
                                    </h4>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-2 col-md-1 col-sm-1"></div>
                                <form className="col-lg-8 col-md-10 col-sm-10" onSubmit={handleSubmit}>
                                    {/* Input Email */}
                                    <div className="form-row">
                                        <div className="col">
                                            <div className="form-group">
                                                <label>Email</label>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    placeholder="Enter work email"
                                                    name="email"
                                                    onChange={handleChange}
                                                    value={email}
                                                    autoFocus
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {/* Submit Button */}
                                    <div className="form-row mt-4">
                                        <div className="col text-center">
                                            <button
                                                type="submit"
                                                className="submit-button"
                                                disabled={email ? false : true}>
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </form>
                                <div className="col-lg-2 col-md-1 col-sm-1"></div>
                            </div>
                        </div>
                        <div className="text-center mt-3">
                            <small>Don't have a Brit's Wine account? <a href='/register'>Sign up!</a></small>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-1"></div>
                </div>
            </div>
        )
    }
}

export default Forgot;