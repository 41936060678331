import React, { Component } from 'react';
import './style.css';
import { isAuth } from '../../helpers/auth';
import axios from 'axios';
import { Redirect } from 'react-router-dom';

class Chooseretailer extends Component {
    constructor() {
        super()
        this.state = {
            first_name: '',
            last_name: '',
            email: '',
            errorAlert: '',
            search: '',
            existing_retailer_id: '',
            existing_retailer_name: '',
            existing_retailer_domain: '',
            retailers: [],
            selected_unrelated_retailer_id: 0,
            selected_unrelated_retailer_Created_By: '',
            selected_unrelated_retailer_Name: ''
        }
    }

    componentDidMount() {
        // Check to see if we have use info in the localstorage ie. if the user is logged in
        if (localStorage.getItem('user')) {
            // Grab the data from the user and store in state for later user
            const user = localStorage.getItem('user');
            const email = JSON.parse(user).email;
            const first_name = JSON.parse(user).first_name;
            const last_name = JSON.parse(user).last_name;
            this.setState({
                email: email,
                first_name: first_name,
                last_name: last_name
            });

            // if we have the currently logged in user's email, then check to see if the email domain matches that of any
            // other users who created retailers in the DB
            if (email) {
                axios.post('/selfserve/api/retailer/existing/find', {
                    email
                })
                    .then(res => {
                        // if success, set the related retailer/user's info in state to be displayed in the related retailer section
                        this.setState({
                            existing_retailer_id: res.data.retailer.id,
                            existing_retailer_name: res.data.retailer.name,
                            existing_retailer_domain: res.data.emailDomain,
                        })
                    })
                    .catch(err => {
                        console.log(err);
                        this.setState({ errorAlert: err.response.data.errors })
                    });
            } else {
                this.setState({ errorAlert: "there is no email in the local storage, which means you are probably not logged in and your token expired. Try logging in again. If that doesn't work, contact sales@britswine.com" })
            }

            // Regardless of the request above, we need to get a list of all of the retailers and their retailer owners
            axios.post('/selfserve/api/retailer/find/all')
                .then(res => {
                    // if we have a list of retailers, store them in state to be displayed
                    if (res.data.retailer) {
                        this.setState({ retailers: res.data.retailer })
                    }
                })
                .catch(err => {
                    console.log(err);
                    this.setState({ errorAlert: err.response.data.errors })
                });
            // potential opportunity to decrease loadtime in the future is to make the created by email domain chopping 
            // process to happen in the backend and then be sent in the response to the frontend so there is no need 
            // for additional js rendering in the frontend
        }
    }

    render() {

        const {
            email,
            first_name,
            last_name,
            existing_retailer_id,
            selected_unrelated_retailer_id,
            selected_unrelated_retailer_Created_By,
            selected_unrelated_retailer_Name
        } = this.state

        // This handles onclick when the user originally clicks on a retailer from the list.
        // It opens the modal and sets the selected values of the retailer to be used when the user confirms
        const handleSelectUnrelatedRetailer = retailer => {
            this.setState({
                selected_unrelated_retailer_id: retailer.id,
                selected_unrelated_retailer_Created_By: retailer.partnerAccountEmail,
                selected_unrelated_retailer_Name: retailer.name
            })
        }

        // This handles when the user clicks to join the related retailer
        const handleJoinRelatedRetailer = e => {
            e.preventDefault()
            // Pass the user's email and the existing retailer id, which was retrieved from the request onComponentDidMount
            axios.post('/selfserve/api/retailer/join/related', {
                email, existing_retailer_id
            })
                .then(res => {
                    // if the record was new send them to the create sommelier page
                    if (res.data.partnerRetailer[1] === true) {
                        this.props.history.push('/sommelier/create');
                    } else {
                        // Otherwise, tell the user that they already joined this retailer
                        this.setState({ errorAlert: "You have already joined this retailer" })
                    }
                })
                .catch(err => {
                    console.log(err);
                    this.setState({ errorAlert: err.response.data.errors })
                });
        }

        // Handles when the user clicks on one of the retailers they are not related to
        const handleJoinUnrelatedRetailer = e => {
            e.preventDefault()
            axios.post('/selfserve/api/retailer/join/unrelated', {
                email,
                first_name,
                last_name,
                selected_unrelated_retailer_id,
                selected_unrelated_retailer_Created_By,
                selected_unrelated_retailer_Name
            })
                .then(res => {
                    console.log(res)
                    // If success, get the domain of the requested retailer owner and store it in sessionstorage
                    // Again this is probably not the best approach, but it does work
                    let createdByDomain = selected_unrelated_retailer_Created_By.replace(/.*@/, "");
                    sessionStorage.setItem("Retailer_Name", this.state.selected_unrelated_retailer_Name);
                    sessionStorage.setItem("Domain", createdByDomain);
                    this.props.history.push('/retailer/join/sent');
                })
                .catch(err => {
                    console.log(err)
                    this.setState({ errorAlert: err.response.data.errors })
                })
        }

        // Handle onChange when the search input value is updated
        const updateSearch = e => {
            this.setState({ search: e.target.value })
        }

        // This filters the retailers list based on what the user is inputting into the search field. this is the filtering functionality
        let filteredRetailers = this.state.retailers.filter(retailer => {
            return retailer.name.toLowerCase().includes(this.state.search.toLowerCase());
        });

        // Handles removing the error alert banners onclick "x"
        const handleErrorAlert = e => {
            e.preventDefault()
            this.setState({ errorAlert: '' });
        }

        // Handles when the user clicks on the create new retailer button, this takes the user to that page
        const handleNewRetailer = e => {
            e.preventDefault()
            this.props.history.push('/retailer/create');
        }

        // Handles when the user clicks on the "I am not a sommelier" button. This takes the user to the create sommelier profile page
        const handleNewSommelier = e => {
            e.preventDefault()
            this.props.history.push('/sommelier/create');
        }

        return (
            <div>
                {isAuth() ? null : <Redirect to='/login' />}
                <div className="container">

                    {/* This is for any alerts/errors */}
                    {(this.state.errorAlert) ?
                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                            {this.state.errorAlert}
                            <button type="button" className="close" onClick={handleErrorAlert} data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        : null}

                    {/*The modal */}
                    <div>
                        <div className="modal fade" id="myModal" role="dialog">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title">Are You Sure?</h4>
                                        <button data-dismiss="modal" aria-label="Close" type="button" className="close"><span aria-hidden="true">&times;</span></button>
                                    </div>
                                    <div className="modal-body">
                                        <p>Are you sure you would like to join this retailer?</p>
                                    </div>
                                    <div className="modal-footer">
                                        <button data-dismiss="modal" aria-label="Close" type="button" className="canceling-button">Cancel</button>
                                        <button data-dismiss="modal" aria-label="Close" type="button" className="submit-button" onClick={handleJoinUnrelatedRetailer}>Continue</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-3 col-md-1"></div>
                        <div className="col-lg-6 col-md-10">
                            <div className="mt-3 pb-4">
                                {/* Title section w/ logo */}
                                <div className="row">
                                    <div className="col text-center">
                                        <img src="./../../images/Brits-Wine-Logo-100w.png" alt="Brits Wine Logo" width="50px" className="mt-3 mb-4" />
                                        <h4>
                                            Find Your Retailer
                                        </h4>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-1 col-md-1 col-sm-1"></div>
                                    <div className="col-lg-10 col-md-10 col-sm-10">
                                        <div>
                                            <div className="row">
                                                <div className="col text-center mt-3">
                                                    <p>
                                                        Associating yourself with a retailer gives you the opportunity
                                                        to take full advantage of everything Brit's Wine has to offer
                                                    </p>
                                                </div>
                                            </div>

                                            {/* This is handles the existing retailer section. Only if there is an existing retailer does this section show */}
                                            {(this.state.existing_retailer_name && this.state.existing_retailer_domain) ?
                                                <div className="join-existing-retailer">
                                                    <div className="row">
                                                        <div className="col">
                                                            <p>
                                                                Your email domain matches other accounts in Brit's Wine.
                                                                Would you like to join this retailer?
                                                        </p>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-lg-3 col-md-3 col-sm-3">
                                                            <button className="submit-button" onClick={handleJoinRelatedRetailer}>
                                                                Join
                                                        </button>
                                                        </div>
                                                        <div className="col-lg-9 col-md-9 col-sm-9">
                                                            <h6>{this.state.existing_retailer_name}</h6>
                                                            <p>({this.state.existing_retailer_domain})</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                : null}

                                            {/* Middle text (will only show the "OR" if there the related retailer section shows up, otherwise the OR will not showup) */}
                                            <div className="row">
                                                <div className="col m-2">
                                                    <small>
                                                        {(this.state.existing_retailer_name && this.state.existing_retailer_domain) ? "OR" : null} Start by searching retailer name
                                                    </small>
                                                </div>
                                            </div>

                                            {/* Search bar */}
                                            <div className="row">
                                                <div className="col">
                                                    <input
                                                        type="search"
                                                        className="search-input mb-1"
                                                        placeholder="Search"
                                                        onChange={updateSearch}
                                                        value={this.state.search}
                                                    />
                                                    <img className="search-icon"
                                                        src="./../../images/Search-Icon.png"
                                                        alt="Search Icon"
                                                        width="12px"
                                                    />
                                                </div>
                                            </div>

                                            {/* Retailer List */}
                                            <div className="row">
                                                <div className="col">
                                                    <div className="vertical-menu">
                                                        {filteredRetailers.map(retailer => (
                                                            <small key={retailer.id}>
                                                                <button
                                                                    id={retailer.id}
                                                                    value={retailer.name}
                                                                    data-toggle="modal"
                                                                    data-target="#myModal"
                                                                    onClick={() => handleSelectUnrelatedRetailer(retailer)}
                                                                >
                                                                    <strong>{retailer.name} ({retailer.partnerAccountEmail.replace(/.*@/, "")})</strong><br></br>
                                                                    Owner: {retailer.partner_account.first_name}
                                                                </button>
                                                            </small>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Create new retailer button */}
                                            <div className="row">
                                                <div className="col">
                                                    <button
                                                        className="choose-retailer-buttons"
                                                        onClick={handleNewRetailer}
                                                    >
                                                        Create a new retailer
                                                    </button>
                                                </div>
                                            </div>

                                            {/* I am not a sommelier button */}
                                            <div className="row">
                                                <div className="col">
                                                    <button
                                                        className="choose-retailer-buttons"
                                                        onClick={handleNewSommelier}
                                                    >
                                                        I am a sommelier not associated with a retailer
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col text-center mt-3">
                                                    <small>Can't find your retailer? <a href='mailto:sales@britswine.com'>Contact us!</a></small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-1 col-md-1 col-sm-1"></div>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-3 col-md-1"></div>
                    </div>
                </div>
            </div >
        )
    }
}

export default Chooseretailer;