import React, { Component } from 'react';
import './style.css';
import { isAuth } from '../../helpers/auth';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import Coworkerinput from '../../components/Coworkerinput/index';

class Invitecoworkers extends Component {
    constructor() {
        super()
        this.state = {
            email: '',
            RetailerId: '',
            errorAlert: '',
            first_name: '',
            last_name: '',
            coworker_email: '',
            coworkerInputs: [],
            first_name0: '',
            last_name0: '',
            coworker_email0: '',
            first_name1: '',
            last_name1: '',
            coworker_email1: '',
            first_name2: '',
            last_name2: '',
            coworker_email2: '',
            first_name3: '',
            last_name3: '',
            coworker_email3: '',
            first_name4: '',
            last_name4: '',
            coworker_email4: '',
            first_name5: '',
            last_name5: '',
            coworker_email5: ''
        }
    }

    componentDidMount() {
        if (localStorage.getItem('user')) {
            const user = localStorage.getItem('user');
            const email = JSON.parse(user).email;
            this.setState({
                email: email,
                RetailerId: sessionStorage.getItem("RetailerId")
            });
        }
    }

    render() {

        const {
            coworkerInputs,
            RetailerId,
            email,
            first_name,
            last_name,
            coworker_email,
            first_name0,
            last_name0,
            coworker_email0,
            first_name1,
            last_name1,
            coworker_email1,
            first_name2,
            last_name2,
            coworker_email2,
            first_name3,
            last_name3,
            coworker_email3,
            first_name4,
            last_name4,
            coworker_email4,
            first_name5,
            last_name5,
            coworker_email5
        } = this.state

        // Handle change from inputs
        const handleChange = e => {
            this.setState({ [e.target.name]: e.target.value })
        }

        // This will create a new row of input fields to add another coworker in the form
        const renderCoworkerInput = e => {
            e.preventDefault()
            // This will work as long as the total number of rows of inputs is less than 7
            if (coworkerInputs.length <= 5) {
                var newInput = coworkerInputs.length;
                // Here we just want to concatenate the number of the row of inputs to the coworker inputs
                this.setState(prevState => ({ coworkerInputs: prevState.coworkerInputs.concat([newInput]) }));
            }
        }

        // Handle removing the specific input values and input row tied to the "x" which the user clicked
        // Basically this handles deleting an input row
        const removeCoworkerInput = i => {
            // this uses es6 filter method to remove the row that was clicked on so the only rows displayed are the rows that were not deleted
            var filteredInputs = coworkerInputs.filter(coworkerInput => coworkerInput !== i);
            // Set the state of the coworker inputs which is the array to the filtered inputs defined above. Also set those input values of
            // The deleted row back to an empty string so no random input values are sent to the backend
            this.setState({
                coworkerInputs: filteredInputs,
                [`first_name${i}`]: '',
                [`last_name${i}`]: '',
                [`coworker_email${i}`]: ''
            });
        }

        // Handle removing the error text onclick "x"
        const handleErrorAlert = e => {
            e.preventDefault()
            this.setState({ errorAlert: '' });
        }

        // Handle on submit inviting the inputted coworkers to Brit's Wine
        const handleInviteCoworkers = e => {
            e.preventDefault()

            // Grab all of the inputs regardless of if they have values in them or not and send to the backend
            axios.post('/selfserve/api/auth/coworkers/invite', {
                email,
                RetailerId,
                first_name,
                last_name,
                coworker_email,
                first_name0,
                last_name0,
                coworker_email0,
                first_name1,
                last_name1,
                coworker_email1,
                first_name2,
                last_name2,
                coworker_email2,
                first_name3,
                last_name3,
                coworker_email3,
                first_name4,
                last_name4,
                coworker_email4,
                first_name5,
                last_name5,
                coworker_email5,
            })
                .then(res => {
                    console.log(res)
                    // dont forget to remove the retailerId from the sessionStorage when the activated page is onComponentDidMount
                    this.props.history.push('/sommelier/create');
                })
                .catch(err => {
                    console.log(err);
                    this.setState({ errorAlert: err.response.data.errors });
                })
        }

        return (
            <div>
                {isAuth() ? null : <Redirect to='/login' />}
                <div className="container">

                    {/* This is for any alerts/errors */}
                    {(this.state.errorAlert) ?
                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                            {this.state.errorAlert}
                            <button type="button" className="close" onClick={handleErrorAlert} data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        : null}

                    {/* Title Section */}
                    <div className="row m-3">
                        <div className="col text-center">
                            <h1>
                                Invite Your Coworkers
                            </h1>
                            <p>
                                Add coworkers from your team to help explore Brit's Wine and what it has to offer
                            </p>
                        </div>
                    </div>

                    <div className="row">
                        {/* Icon on left side */}
                        <div className="col-lg-4">
                            <img src="./../../images/Invite-coworkers-icon.png" alt="Invite Coworkers Icon" width="300px" style={{ marginTop: "75px" }} />
                        </div>

                        <div className="col-lg-8">
                            <form onSubmit={handleInviteCoworkers}>
                                <div className="row">
                                    {/* First Name Input */}
                                    <div className="col-lg-3">
                                        <div className="form-row">
                                            <div className="col">
                                                <div className="form-group">
                                                    <label>First Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter first name"
                                                        name="first_name"
                                                        onChange={handleChange}
                                                        value={first_name}
                                                        autoFocus
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Last Name Input */}
                                    <div className="col-lg-3">
                                        <div className="form-row">
                                            <div className="col">
                                                <div className="form-group">
                                                    <label>Last Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter last name"
                                                        name="last_name"
                                                        onChange={handleChange}
                                                        value={last_name}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Email Input */}
                                    <div className="col-lg-6">
                                        <div className="form-row">
                                            <div className="col">
                                                <div className="form-group">
                                                    <label>Email</label>
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        placeholder="Enter coworker's email"
                                                        name="coworker_email"
                                                        onChange={handleChange}
                                                        value={coworker_email}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Map out the rows of coworker Inputs */}
                                {coworkerInputs.map(index =>
                                    <Coworkerinput
                                        key={index}
                                        id={index}
                                        handleChange={handleChange}
                                        removeInput={removeCoworkerInput}
                                    />)}

                                {/* Add more coworkers Button */}
                                <div className="row">
                                    <div className="col">
                                        <button
                                            type="button"
                                            className="add-coworker-button"
                                            onClick={renderCoworkerInput}
                                            disabled={coworkerInputs.length <= 5 ? false : true}
                                        >
                                            + Add more coworkers
                                        </button>
                                    </div>
                                </div>

                                <div className="row" style={{ marginTop: "250px" }}>
                                    <div className="col-lg-7"></div>
                                    {/* Skip Button */}
                                    <div className="col-lg-2 pt-2">
                                        <a
                                            className="skip"
                                            href="/sommelier/create"
                                        >
                                            Skip
                                        </a>
                                    </div>

                                    {/* Invite Coworkers Button */}
                                    <div className="col-lg-3">
                                        <div className="form-row">
                                            <button
                                                type="submit"
                                                className="submit-button"
                                                disabled=
                                                {first_name &&
                                                    last_name &&
                                                    coworker_email ?
                                                    false : true}
                                            >
                                                Invite Coworkers
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

export default Invitecoworkers;