import React, { Component } from 'react';
import './style.css';
import { isAuth } from '../../helpers/auth';
import axios from 'axios';
import { Redirect } from 'react-router-dom';

class Createsommelier extends Component {
    constructor() {
        super()
        this.state = {
            first_name: '',
            email: '',
            errorAlert: '',
            title: '',
            file_name: '',
            file_public_id: '',
            image_preview_url: '',
            uploadPercentage: 0
        }
    }

    componentDidMount() {
        if (localStorage.getItem('user')) {
            const user = localStorage.getItem('user');
            const email = JSON.parse(user).email;
            this.setState({ email: email });
            
            if (email) {
                // Check to see if the user has already created a sommelier profile. When they come to this page, it is possible
                // they already have created a record. We dont want the user to make multiple sommelier profiles, so we check to
                // see if one exists, if the record exists, send the user straight to the home page. Otherwise, let the user fill out the page
                axios.post('/selfserve/api/sommelier/profile/find', {
                    email
                })
                    .then(res => {
                        if (res.data.sommelier) {
                            this.props.history.push('/')
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        this.setState({ errorAlert: err.response.data.errors })
                    })
            }
        }
    }

    render() {

        const { email, title, file_name, image_preview_url, uploadPercentage } = this.state

        // Handle change from inputs
        const handleChange = e => {
            this.setState({ [e.target.name]: e.target.value })
        }

        // Handle file upload for profile image
        const handleFileUpload = e => {
            e.preventDefault()
            // We are only grabbing the first file selected. If someone tries to select to upload more than one
            // There will not be any error, but we will only grab the first file selected
            console.log(e.target.files[0]);
            // Define the file type of the selected type for png, jpeg and jpg
            let file_type_png = e.target.files[0].type.toLowerCase().includes("png");
            let file_type_jpeg = e.target.files[0].type.toLowerCase().includes("jpeg");
            let file_type_jpg = e.target.files[0].type.toLowerCase().includes("jpg");
            // Define the file size of the selected file
            let file_size = e.target.files[0].size;

            // Check to see if the file type is png, jpeg or jpg
            // Here is a potential future improvement for checking file type: https://stackoverflow.com/questions/18299806/how-to-check-file-mime-type-with-javascript-before-upload
            if (file_type_png || file_type_jpeg || file_type_jpg) {
                // Check to see if the file size is less than or equal to 1,000,000 bytes or 1mb
                if (file_size <= 1000000) {
                    // Define the selected file
                    var file = e.target.files[0];
                    // Create a new FormData object which is a built-in js object we need to send file data.
                    // Here we are just defining the new object
                    // ***** For future reference a potential way to maybe speed up this upload process is to maybe convert to the
                    // file to base64 encode here in the frontend and then send it to the backend someway so that we can plug that
                    // right into the cloudinary api. Thus, we would not need multer, potentially idk *****
                    var formData = new FormData();
                    // Then we need to append the selected file to the FormData object under the name "file"
                    formData.append('file', file);
                    // Send a post request using a content type of "multipart/form-data" which is the encoding type to encode
                    // the file data. Then send the formdata object in the request
                    // Also onUploadProgress is a built-in attribute that we use to monitor and report on the progress of the upload
                    // We follow that with setting a percent variable that will change as the upload progress changes in the form of a number
                    // As that changes progress changes, we set the state of the uploadPercentage which we then send to the actual progress bar below
                    axios({
                        url: '/selfserve/api/sommelier/image/upload',
                        method: 'POST',
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        data: formData,
                        onUploadProgress: (progressEvent) => {
                            let percent = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                            this.setState({ uploadPercentage: percent })
                        }
                    })
                        .then(res => {
                            // When we get the response, set the state of the url, name and id of the uploaded cloudinary id.
                            // Also set the upload percentage to 0
                            console.log(res.data.image)
                            this.setState({
                                image_preview_url: res.data.image.secure_url,
                                file_name: file.name,
                                file_public_id: res.data.image.public_id,
                                uploadPercentage: 0
                            })
                        })
                        .catch(err => {
                            this.setState({ errorAlert: err.response.data.errors })
                        });
                } else {
                    this.setState({ errorAlert: "The file size you uploaded needs to be less than 1mb" })
                }
            } else {
                console.log("Bad file type")
                this.setState({ errorAlert: "The file format you uploaded needs to be a png, jpeg or jpg" })
            }
        }

        // ****** This is code for uploading images to cloudinary via the frontend. It is a helpful reference and can be used if the backend stops working
        // const handleFileUpload = e => {
        //     e.preventDefault()
        //     console.log(fileInput.current.files[0]); //Bing bing bing!! this is the one!
        //     console.log(fileInput.current.files[0].name);
        //     console.log(fileInput.current.files[0].type);

        //     var file = fileInput.current.files[0];
        //     this.setState({ file_name: file.name })
        //     var formData = new FormData();
        //     formData.append('file', file);
        //     formData.append('upload_preset', CLOUDINARY_UPLOAD_PRESET);

        //     // Need to add file size and format validations

        //     axios({
        //         url: CLOUDINARY_URL,
        //         method: 'POST',
        //         headers: {
        //             'Content-Type': 'application/x-www-form-urlencoded'
        //         },
        //         data: formData
        //     })
        //         .then(res => {
        //             console.log(res)
        //             this.setState({ image_preview_url: res.data.secure_url })
        //         })
        //         .catch(err => {
        //             console.log(err)
        //         })
        //     // this.setState({ [e.target.name]: e.target.value })
        // }

        // Handles removing the file from the frontend and the cloudinary server
        const handleRemoveFile = e => {
            e.preventDefault()
            axios.post('/selfserve/api/sommelier/image/destroy', {
                image_preview_url
            })
                .then(res => {
                    this.setState({
                        image_preview_url: '',
                        file_name: '',
                        file_public_id: ''
                    })
                })
                .catch(err => {
                    console.log(err);
                    this.setState({ errorAlert: err.response.data.errors })
                })
        }

        // Handle create sommelier
        const handleCreateSommelier = e => {
            e.preventDefault()
            // Grab the form data and send it to create the new sommelier record
            axios.post('/selfserve/api/sommelier/profile/create', {
                email, title, image_preview_url
            })
                .then(res => {
                    if (sessionStorage.getItem("RetailerId")) {
                        sessionStorage.removeItem("RetailerId");
                    }
                    this.props.history.push('/')
                })
                .catch(err => {
                    console.log(err)
                    this.setState({ errorAlert: err.response.data.errors })
                })
        }

        // Handle moving error text onclick "x"
        const handleErrorAlert = e => {
            e.preventDefault()
            this.setState({ errorAlert: '' });
        }

        return (
            <div>
                {isAuth() ? null : <Redirect to='/login' />}
                <div className="container">

                    {/* This is for any alerts/errors */}
                    {(this.state.errorAlert) ?
                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                            {this.state.errorAlert}
                            <button type="button" className="close" onClick={handleErrorAlert} data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        : null}

                    <div className="row">
                        <div className="col-lg-3 col-md-1"></div>
                        <div className="col-lg-6 col-md-10">
                            <div className="mt-3 pb-4">
                                {/* Title w/ logo */}
                                <div className="row">
                                    <div className="col text-center">
                                        <img src="./../../images/Brits-Wine-Logo-100w.png" alt="Brits Wine Logo" width="50px" className="mt-3 mb-4" />
                                        <h4>
                                            Add Sommelier Profile
                                        </h4>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-1 col-md-1 col-sm-1"></div>
                                    <form className="col-lg-10 col-md-10 col-sm-10">
                                        {/* Input Title */}
                                        <div className="form-row">
                                            <div className="col-lg">
                                                <div className="form-group">
                                                    <label>Title</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter job title"
                                                        name="title"
                                                        onChange={handleChange}
                                                        value={title}
                                                        autoFocus
                                                        required
                                                    />
                                                    <small>Enter your professional role ex. Head Sommelier</small>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Upload File */}
                                        <div className="form-row">
                                            <div className="col-lg">
                                                <div className="form-group">
                                                    <label htmlFor="file">Profile Picture</label>
                                                    <div className="file-input-wrapper">
                                                        <div className="row">
                                                            {/* If someone added a file, display the file name otherwise display no file selected */}
                                                            <div className="col-8 pt-2">
                                                                {file_name ?
                                                                    <p className="file-selected-true">{file_name}</p>
                                                                    : <p className="file-selected-false">No file selected</p>
                                                                }
                                                            </div>
                                                            <div className="col-4">
                                                                <input
                                                                    type="file"
                                                                    id="file"
                                                                    name="file"
                                                                    onChange={handleFileUpload}
                                                                    className="file-input-button"
                                                                    required
                                                                />
                                                                <label htmlFor="file">Add File</label>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <small>Files maximum 1mb, formats: jpeg, jpg, png</small>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Upload File Progress Bar */}
                                        {uploadPercentage > 0 ?
                                            <div className="form-row mb-2">
                                                <div className="col-lg">
                                                    <div className="progress">
                                                        <div className="progress-bar progress-bar-striped bg-info progress-bar-animated"
                                                            role="progressbar"
                                                            aria-valuenow={uploadPercentage}
                                                            aria-valuemin="0"
                                                            aria-valuemax="100"
                                                            style={{ width: `${uploadPercentage}%` }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            : null}

                                        {/* Image preview Thumbnail */}
                                        <div className="form-row">
                                            <div className="col-lg">
                                                <div className="form-group profile-picture-thumbnail">
                                                    {image_preview_url ?
                                                        <div>
                                                            <img src={image_preview_url} alt="Upload Preview" />
                                                            <button onClick={handleRemoveFile}><span>&times;</span></button>
                                                        </div>
                                                        : <p>No file selected</p>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-row">
                                            {/* I am not a sommelier button */}
                                            <div className="col-8 text-center pt-1">
                                                <a className="not-sommelier" href="/">
                                                    I am not a sommelier
                                                    </a>
                                            </div>
                                            {/* Create Sommelier Button */}
                                            <div className="col-4">
                                                <button
                                                    type="submit"
                                                    className="submit-button"
                                                    onClick={handleCreateSommelier}
                                                    disabled=
                                                    {image_preview_url &&
                                                        title ?
                                                        false : true}
                                                >
                                                    Create
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                    <div className="col-lg-1 col-md-1 col-sm-1"></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-1"></div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Createsommelier;