import React, { Component } from 'react';
import { isAuth } from '../../helpers/auth';
import axios from 'axios';
import { Redirect } from 'react-router-dom';

class Register extends Component {
    constructor() {
        super()
        this.state = {
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            isPasswordShown: false,
            hasNum: false,
            hasSpecial: false,
            hasMin: false,
            alert: ''
        }
    }


    render() {

        const { email, first_name, last_name, password, isPasswordShown, hasNum, hasSpecial, hasMin } = this.state

        // This toggles the visibility of the eye icon and the input type for the password whenever the eye icon is clicked
        const togglePasswordVisibility = () => {
            const { isPasswordShown } = this.state;
            this.setState({ isPasswordShown: !isPasswordShown })
        }

        // Handle change from inputs
        const handleChange = e => {
            this.setState({ [e.target.name]: e.target.value })
        }

        // Handle change from password inputs
        const handlePasswordChange = e => {
            this.setState({ [e.target.name]: e.target.value })

            // Validation if contains number
            let containsNumber = e.target.value.match(/\d+/g);
            if (containsNumber !== null) {
                this.setState({ hasNum: true })
            } else {
                this.setState({ hasNum: false })
            }

            // Validation if contains a special character
            let containsSpecial = e.target.value.match(/[\s~`!@#$%&*+=\-\]\\';,/{}|\\":<>()_]/g);
            if (containsSpecial !== null) {
                this.setState({ hasSpecial: true })
            } else {
                this.setState({ hasSpecial: false })
            }

            // Validation if the password contains at least 10 characters
            if (e.target.value.length >= 10) {
                this.setState({ hasMin: true })
            } else {
                this.setState({ hasMin: false })
            }
        }

        // Handles removing the alert message inclucing the alert message text from state whenever user clicks the "x" button
        const handleAlert = e => {
            e.preventDefault()
            this.setState({ alert: '' });
        }

        // Submit data to backend
        const handleSubmit = e => {

            e.preventDefault()
            // This if statement is a fallback for if for some reason the user is able to click the disabled button,
            // in order to post the same conditions of all fields and validations need to apply
            if (first_name && last_name && email && password && hasNum && hasSpecial && hasMin) {
                axios.post('/selfserve/api/auth/register', {
                    first_name, last_name, email, password
                }).then(res => {
                    this.setState({
                        first_name: '',
                        last_name: '',
                        password: ''
                    });
                    // Store the email in session storage. We need to do this to retrieve it on the confirmation sent page to display where the email was sent
                    // this is a bit of temporary solution. Probably a better way to do this.
                    sessionStorage.setItem("email", this.state.email);
                    // Direct the page to the email confirmation sent
                    this.props.history.push('/confirmation/sent');

                })
                    .catch(err => {
                        // Display the error if there is an error
                        this.setState({ alert: err.response.data.errors })
                    });
            } else {
                // Display the error if there is an error
                this.setState({ alert: "Please fill all fields" })
            }
        }

        return (
            <div>
                {/* This will redirect the user if they are logged in to go to the home page, otherwise nothing */}
                {isAuth() ? <Redirect to='/' /> : null}
                <div className="container">

                    {/* This is for any alerts/errors */}
                    {(this.state.alert) ?
                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                            {this.state.alert}
                            <button type="button" className="close" onClick={handleAlert} data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        : null}

                    <div className="row">
                        <div className="col-lg-3 col-md-1"></div>
                        <div className="col-lg-6 col-md-10">
                            <div className="register-form mt-5 pb-4">
                                {/* Title section w/ logo */}
                                <div className="row">
                                    <div className="col text-center">
                                        <img src="./../../images/Brits-Wine-Logo-200w.png" alt="Brits Wine Logo" width="100px" className="mt-3 mb-3" />
                                        <h4>
                                            Sign up for Brit's Wine
                                        </h4>
                                    </div>
                                </div>

                                {/* Register form inputs */}
                                <div className="row">
                                    <div className="col-lg-2 col-md-1 col-sm-1"></div>
                                    <form className="col-lg-8 col-md-10 col-sm-10" onSubmit={handleSubmit}>
                                        <div>
                                            <div className="form-row">
                                                <div className="col-lg">
                                                    <div className="form-group">
                                                        <label>First Name</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter first name"
                                                            name="first_name"
                                                            onChange={handleChange}
                                                            value={first_name}
                                                            autoFocus
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg">
                                                    <div className="form-group">
                                                        <label>Last Name</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter last name"
                                                            name="last_name"
                                                            onChange={handleChange}
                                                            value={last_name}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-row">
                                                <div className="col">
                                                    <div className="form-group">
                                                        <label>Email</label>
                                                        <input
                                                            type="email"
                                                            className="form-control"
                                                            placeholder="Enter work email"
                                                            name="email"
                                                            onChange={handleChange}
                                                            value={email}
                                                            required
                                                        />
                                                        <small>Do not have a company email? <a href="mailto:sales@britswine.com">Contact us!</a></small>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Password section */}
                                            <div className="form-row">
                                                <div className="col">
                                                    <div className="form-group">
                                                        <label>Password</label>
                                                        <input
                                                            type={(isPasswordShown) ? "text" : "password"}
                                                            className="form-control"
                                                            placeholder="Enter password"
                                                            name="password"
                                                            onChange={handlePasswordChange}
                                                            value={password}
                                                            maxLength="50"
                                                            required
                                                        />
                                                        <img className="password-icon"
                                                            src={(isPasswordShown) ? "./../../images/Eye-Icon-Hide.png" : "./../../images/Eye-Icon-Show.png"}
                                                            alt="Eye Icon"
                                                            width="25px"
                                                            onClick={togglePasswordVisibility}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Password validations */}
                                            <div className="form-row text-center">
                                                <div className="col">
                                                    <small className={hasMin ? "success" : null}>10 characters min</small>
                                                </div>
                                                <div className="col">
                                                    <small className={hasSpecial ? "success" : null}>1 special character</small>
                                                </div>
                                                <div className="col">
                                                    <small className={hasNum ? "success" : null}>1 number</small>
                                                </div>
                                            </div>

                                            {/* Strong password validation */}
                                            <div className="form-row mt-1">
                                                <div className="col text-center">
                                                    {(hasMin && hasSpecial && hasNum) ? <p className="success-complete">Strong password!</p> : null}
                                                </div>
                                            </div>

                                            {/* Terms agreement */}
                                            <div className="form-row">
                                                <div className="col">
                                                    <small>By clicking <strong>Register</strong>, you are indicating that you have read and agreed to the <a href="https://www.britswine.com/terms.html">terms of service</a>.</small>
                                                </div>
                                            </div>

                                            {/* Submit button - This will only be enabled when all of the conditions of the form are met */}
                                            <div className="form-row mt-4">
                                                <div className="col text-center">
                                                    <button
                                                        type="submit"
                                                        className="submit-button"
                                                        disabled=
                                                        {first_name &&
                                                            last_name &&
                                                            email &&
                                                            hasMin &&
                                                            hasSpecial &&
                                                            hasNum ?
                                                            false : true}>
                                                        Register
                                                    </button>
                                                </div>
                                            </div>

                                        </div>

                                    </form>
                                    <div className="col-lg-2 col-md-1 col-sm-1"></div>
                                </div>
                            </div>
                            <div className="text-center mt-3">
                                <small>Already have a Brit's Wine account? <a href='/login'>Login</a></small>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-1">

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Register;