import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Register from './pages/Register/index';
import Registersent from './pages/Registersent/index';
import Login from './pages/Login/index';
import Forgot from './pages/Forgotpassword/index';
import Activate from './pages/Activate/index';
import Reset from './pages/Resetpassword/index';
import Chooseretailer from './pages/Chooseretailer/index';
import Createsommelier from './pages/Createsommelier/index';
import Joinsent from './pages/Joinsent/index';
import Retaileractivation from './pages/Retaileractivation/index';
import Createretailer from './pages/Createretailer/index';
import Invitecoworkers from './pages/Invitecoworkers/index';
import Activatecoworker from './pages/Activatecoworker/index';
import Home from './pages/Home/index';
import Editretailer from './pages/Editretailer/index';
import Account from './pages/Account/index';
import Editprofile from './pages/Editprofile/index';
import Uploadproducts from './pages/Uploadproducts/index';
import Editretailerproduct from './pages/Editretailerproduct/index';
import * as serviceWorker from './serviceWorker';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Elements stripe={stripePromise}>
        <Route path='/' exact render={props => <Home {...props} />} />
        <Route path='/register' exact render={props => <Register {...props} />} />
        <Route path='/confirmation/sent' exact render={props => <Registersent {...props} />} />
        <Route path='/login' exact render={props => <Login {...props} />} />
        <Route path='/users/password/forgot' exact render={props => <Forgot {...props} />} />
        <Route path='/users/activate/:token' exact render={props => <Activate {...props} />} />
        <Route path='/users/password/reset/:token' exact render={props => <Reset {...props} />} />
        <Route path='/choose-retailer' exact render={props => <Chooseretailer {...props} />} />
        <Route path='/sommelier/create' exact render={props => <Createsommelier {...props} />} />
        <Route path='/retailer/join/sent' exact render={props => <Joinsent {...props} />} />
        <Route path='/retailer/activate/:token' exact render={props => <Retaileractivation {...props} />} />
        <Route path='/retailer/create' exact render={props => <Createretailer {...props} />} />
        <Route path='/retailer/invite/coworkers' exact render={props => <Invitecoworkers {...props} />} />
        <Route path='/coworkers/activate/:token' exact render={props => <Activatecoworker {...props} />} />
        <Route path='/retailer/edit/:id' exact render={props => <Editretailer {...props} />} />
        <Route path='/account' exact render={props => <Account {...props} />} />
        <Route path='/sommelier/profile/edit' exact render={props => <Editprofile {...props} />} />
        <Route path='/products/upload' exact render={props => <Uploadproducts {...props} />} />
        <Route path='/product/edit/:id' exact render={props => <Editretailerproduct {...props} />} />
      </Elements>
    </Switch>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();