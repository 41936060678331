import React, { Component } from 'react';
import { isAuth } from '../../helpers/auth';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import Inventory_Systems from '../../inventory_systems.json';

class Createretailer extends Component {
    constructor() {
        super()
        this.state = {
            first_name: '',
            email: '',
            errorAlert: '',
            retailer_name: '',
            display_name: '',
            sales_email: '',
            file_name: '',
            file_public_id: '',
            image_preview_url: '',
            uploadPercentage: 0,
            other_pos: '',
            Inventory_Systems
        }
    }

    componentDidMount() {
        if (localStorage.getItem('user')) {
            const user = localStorage.getItem('user');
            const email = JSON.parse(user).email;
            this.setState({ email: email });
        }
    }

    render() {
        const { other_pos, sales_email, display_name, email, retailer_name, file_name, image_preview_url, uploadPercentage, Inventory_Systems } = this.state

        // Handle change from inputs
        const handleChange = e => {
            this.setState({ [e.target.name]: e.target.value })
        }

        // Handle File upload - see createsommelier page for more info
        const handleFileUpload = e => {
            e.preventDefault()
            console.log(e.target.files[0]);
            let file_type_png = e.target.files[0].type.toLowerCase().includes("png");
            let file_type_jpeg = e.target.files[0].type.toLowerCase().includes("jpeg");
            let file_type_jpg = e.target.files[0].type.toLowerCase().includes("jpg");
            let file_size = e.target.files[0].size;

            if (file_type_png || file_type_jpeg || file_type_jpg) {
                if (file_size <= 1000000) {
                    var file = e.target.files[0];
                    var formData = new FormData();
                    formData.append('file', file);

                    axios({
                        url: '/selfserve/api/retailer/logo/upload',
                        method: 'POST',
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        data: formData,
                        onUploadProgress: (progressEvent) => {
                            let percent = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                            this.setState({ uploadPercentage: percent })
                        }
                    })
                        .then(res => {
                            console.log(res.data.image)
                            this.setState({
                                image_preview_url: res.data.image.secure_url,
                                file_name: file.name,
                                file_public_id: res.data.image.public_id,
                                uploadPercentage: 0
                            })
                        })
                        .catch(err => {
                            console.log(err)
                            this.setState({ errorAlert: err.response.data.errors })
                        });
                } else {
                    this.setState({ errorAlert: "The file size you uploaded needs to be less than 1mb" })
                }
            } else {
                this.setState({ errorAlert: "The file format you uploaded needs to be a png, jpeg or jpg" })
            }
        }

        // Handle remove file from state and cloudinary
        const handleRemoveFile = e => {
            e.preventDefault()
            axios.post('/selfserve/api/retailer/logo/destroy', {
                image_preview_url
            })
                .then(res => {
                    console.log(res);
                    this.setState({
                        image_preview_url: '',
                        file_name: '',
                        file_public_id: ''
                    })
                })
                .catch(err => {
                    console.log(err);
                })
        }

        // Handle create retailer
        const handleCreateRetailer = e => {
            e.preventDefault()
            axios.post('/selfserve/api/retailer/create', {
                retailer_name,
                display_name,
                sales_email,
                image_preview_url,
                other_pos,
                email
            })
                .then(res => {
                    // I added the retailerId to the session storage for the invite coworkers page. It is not ideal, but it works.
                    sessionStorage.setItem("RetailerId", res.data.RetailerId);
                    this.props.history.push('/retailer/invite/coworkers');
                })
                .catch(err => {
                    console.log(err)
                    this.setState({ errorAlert: err.response.data.errors })
                })
        }

        // Handle remove error text onclick "x"
        const handleErrorAlert = e => {
            e.preventDefault()
            this.setState({ errorAlert: '' });
        }

        return (
            <div>
                {isAuth() ? null : <Redirect to='/login' />}
                <div className="container">

                    {/* This is for any alerts/errors */}
                    {(this.state.errorAlert) ?
                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                            {this.state.errorAlert}
                            <button type="button" className="close" onClick={handleErrorAlert} data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        : null}

                    {/* Title section */}
                    <div className="row">
                        <div className="col text-center">
                            <h1>
                                Create New Retailer
                            </h1>
                            <p>
                                Setup your retailer in the Brit's Wine network!
                            </p>
                        </div>
                    </div>

                    <form className="row" onSubmit={handleCreateRetailer}>
                        <div className="col-lg-4">
                            {/* Retailer Name Input */}
                            <div className="form-row">
                                <div className="col">
                                    <div className="form-group">
                                        <label>Retailer Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter retailer name"
                                            name="retailer_name"
                                            onChange={handleChange}
                                            value={retailer_name}
                                            autoFocus
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* Display Name Input */}
                            <div className="form-row mt-4">
                                <div className="col">
                                    <div className="form-group">
                                        <label>Retailer Display Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter retailer display name"
                                            name="display_name"
                                            onChange={handleChange}
                                            value={display_name}
                                            required
                                        />
                                        <small>This is the name the public will see</small>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-4">
                            {/* Sales Email Input */}
                            <div className="form-row">
                                <div className="col">
                                    <div className="form-group">
                                        <label>Sales Email</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            placeholder="Enter sales email"
                                            name="sales_email"
                                            onChange={handleChange}
                                            value={sales_email}
                                            required
                                        />
                                        <small>This is how users will contact you</small>
                                    </div>
                                </div>
                            </div>

                            {/* File input */}
                            <div className="form-row">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="file">Retailer Logo</label>
                                        <div className="file-input-wrapper">
                                            <div className="row">
                                                <div className="col-7 pt-3">
                                                    {file_name ?
                                                        <p className="file-selected-true">{file_name}</p>
                                                        : <p className="file-selected-false">No file selected</p>
                                                    }
                                                </div>
                                                <div className="col-5 pt-2">
                                                    <input
                                                        type="file"
                                                        id="file"
                                                        name="file"
                                                        onChange={handleFileUpload}
                                                        className="file-input-button"
                                                        required
                                                    />
                                                    <label htmlFor="file">Add File</label>
                                                </div>
                                            </div>

                                        </div>
                                        <small>Files maximum 1mb, formats: jpeg, jpg, png</small>
                                    </div>
                                </div>
                            </div>

                            {/* Upload File Progress Bar */}
                            {uploadPercentage > 0 ?
                                <div className="form-row mb-2">
                                    <div className="col">
                                        <div className="progress">
                                            <div className="progress-bar progress-bar-striped bg-info progress-bar-animated"
                                                role="progressbar"
                                                aria-valuenow={uploadPercentage}
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                                style={{ width: `${uploadPercentage}%` }}></div>
                                        </div>
                                    </div>
                                </div>
                                : null}

                            {/* Logo Preview Thumbnail */}
                            <div className="form-row">
                                <div className="col-lg">
                                    <div className="form-group profile-picture-thumbnail">
                                        {image_preview_url ?
                                            <div>
                                                <img src={image_preview_url} alt="Logo Preview" />
                                                <button onClick={handleRemoveFile}><span>&times;</span></button>
                                            </div>
                                            : <p>No file selected</p>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-4">
                            {/* Inventory system Input */}
                            <div className="form-row">
                                <div className="col">
                                    <div className="form-group">
                                        <label>Which inventory system do you use?</label>
                                        <select className="custom-select" onChange={handleChange} name="other_pos" defaultValue={'DEFAULT'} required>
                                            <option disabled value="DEFAULT">Choose...</option>
                                            {Inventory_Systems.map(system => (
                                                <option key={`${system.name}`} value={`${system.name}`}>{`${system.name}`}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            {/* Create Retailer Button */}
                            <div className="form-row" style={{ marginTop: "350px", marginLeft: "200px" }}>
                                <button
                                    type="submit"
                                    className="submit-button"
                                    disabled=
                                    {retailer_name &&
                                        display_name &&
                                        sales_email &&
                                        file_name &&
                                        image_preview_url &&
                                        other_pos ?
                                        false : true}
                                >
                                    Create Retailer
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div >
        )
    }
}

export default Createretailer;