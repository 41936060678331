import React, { Component } from 'react';
import { authenticate, isAuth } from '../../helpers/auth';
import axios from 'axios';
import { Redirect } from 'react-router-dom';

class Login extends Component {
    constructor() {
        super()
        this.state = {
            email: '',
            password: '',
            isPasswordShown: false,
            alert: ''
        }
    }


    render() {

        const { email, password, isPasswordShown, alert } = this.state

        // Toggle password visibility
        const togglePasswordVisibility = () => {
            const { isPasswordShown } = this.state;
            this.setState({ isPasswordShown: !isPasswordShown })
        }

        // Handle change from inputs
        const handleChange = e => {
            this.setState({ [e.target.name]: e.target.value })
        }

        // Remove alert message when clicking "x"
        const handleAlert = e => {
            e.preventDefault()
            this.setState({ alert: '' });
        }

        // Submit data to backend to login user
        const handleSubmit = e => {

            e.preventDefault()
            // This if statement is a fallback for if for some reason the user is able to click the disabled button,
            // in order to post the same conditions of all fields and validations need to apply
            if (email && password) {
                axios.post('/selfserve/api/auth/login', {
                    email, password
                }).then(res => {
                    // Authenticate the user
                    authenticate(res, () => {
                        this.setState({
                            email: '',
                            password: ''
                        });
                    })
                    // If user is authenticated direct the user to the home page
                    if (isAuth()) {
                        this.props.history.push('/');
                    }
                    // This is a success message, but because we are already redirecting the user right above,
                    // we will probably never see this message because we will already be on the next page
                    this.setState({ alert: `Login successful! Hey ${res.data.user.first_name}, welcome back!` })
                })
                    .catch(err => {
                        console.log(err)
                        this.setState({ alert: err.response.data.errors })
                    });
            } else {
                this.setState({ alert: "Please fill all fields" });
            }
        }

        return (
            <div>
                {isAuth() ? <Redirect to='/' /> : null}
                <div className="container">

                    {/* This is for any alerts/errors */}
                    {(alert) ?
                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                            {alert}
                            <button type="button" className="close" onClick={handleAlert} data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        : null}

                    <div className="row">
                        <div className="col-lg-3 col-md-1"></div>
                        <div className="col-lg-6 col-md-10">
                            <div className="register-form mt-5 p-5">
                                {/* Title section w/ logo */}
                                <div className="row">
                                    <div className="col text-center">
                                        <img src="./../../images/Brits-Wine-Logo-200w.png" alt="Brits Wine Logo" width="100px" className="mt-3 mb-3" />
                                        <h4>
                                            Login to Brit's Wine
                                        </h4>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-2 col-md-1 col-sm-1"></div>
                                    <form className="col-lg-8 col-md-10 col-sm-10" onSubmit={handleSubmit}>
                                        <div>
                                            {/* Input email */}
                                            <div className="form-row">
                                                <div className="col">
                                                    <div className="form-group">
                                                        <label>Email</label>
                                                        <input
                                                            type="email"
                                                            className="form-control"
                                                            placeholder="Enter work email"
                                                            name="email"
                                                            onChange={handleChange}
                                                            value={email}
                                                            autoFocus
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Input password */}
                                            <div className="form-row">
                                                <div className="col">
                                                    <div className="form-group">
                                                        <label>Password</label>
                                                        <input
                                                            type={(isPasswordShown) ? "text" : "password"}
                                                            className="form-control"
                                                            placeholder="Enter password"
                                                            name="password"
                                                            onChange={handleChange}
                                                            value={password}
                                                            maxLength="50"
                                                            required
                                                        />
                                                        <img className="password-icon"
                                                            src={(isPasswordShown) ? "./../../images/Eye-Icon-Hide.png" : "./../../images/Eye-Icon-Show.png"}
                                                            alt="password eye icon"
                                                            width="25px"
                                                            onClick={togglePasswordVisibility}
                                                        />
                                                        <div className="text-right">
                                                            <small>
                                                                <a href='/users/password/forgot'>
                                                                    Forgot password?
                                                                </a>
                                                            </small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Login button */}
                                            <div className="form-row mt-4">
                                                <div className="col text-center">
                                                    <button
                                                        type="submit"
                                                        className="submit-button"
                                                        disabled={email && password ? false : true}>
                                                        Login
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <div className="col-lg-2 col-md-1 col-sm-1"></div>
                                </div>
                            </div>
                            <div className="text-center mt-3">
                                <small>Don't have a Brit's Wine account? <a href='/register'>Sign up!</a></small>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-1"></div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Login;