import React from 'react';
import { ElementsConsumer, CardElement } from '@stripe/react-stripe-js';
import axios from 'axios';
import States from './../../states.json';
// Super helpful docs for setting up stripe setupintent and paymentintent:
// https://stripe.com/docs/payments/save-and-reuse#web-setup

class CardSetupForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            States,
            line1: '',
            line2: '',
            city: '',
            state_address: '',
            full_name: '',
            email: '',
            loading: false,
        }
    }

    componentDidMount() {
        if (localStorage.getItem('user')) {
            const user = localStorage.getItem('user');
            const first_name = JSON.parse(user).first_name;
            const last_name = JSON.parse(user).last_name;
            const full_name = first_name + " " + last_name;
            const email = JSON.parse(user).email;
            this.setState({
                full_name: full_name,
                email: email
            });
        }
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        const { email } = this.state;
        this.setState({ loading: true })

        const { data: client_secret } = await axios.post('/selfserve/api/auth/savecard', {
            email
        });

        const { stripe, elements } = this.props

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make  sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const result = await stripe.confirmCardSetup(client_secret, {
            payment_method: {
                card: elements.getElement(CardElement),
                billing_details: {
                    address: {
                        city: this.state.city,
                        line1: this.state.line1,
                        line2: this.state.line2,
                        state: this.state.state_address,
                    },
                    name: this.state.full_name,
                    email: this.state.email,
                },
            }
        });

        if (result.error) {
            // Display result.error.message in your UI.
            console.log(result.error)
            this.setState({ loading: false })
        } else {
            // The setup has succeeded. Display a success message and send
            // result.setupIntent.payment_method to your server to save the
            // card to a Customer
            let card = result.setupIntent.payment_method;
            console.log(card);
            axios.post('/selfserve/api/auth/add/card', {
                card, email
            }).then(res => {
                console.log(res.data);
                this.setState({ loading: false })
                this.props.props.handleUploadProducts()
            }).catch(err => {
                console.log(err)
                this.setState({ loading: false })
            })
        }
    };

    render() {
        const { line1, line2, city, States, loading } = this.state;

        // Handle change from inputs
        const handleChange = e => {
            this.setState({ [e.target.name]: e.target.value })
        }

        return (
            <form onSubmit={this.handleSubmit}>
                <h5>Billing Details</h5>
                <div className="form-row">
                    <div className="form-group col">
                        <input
                            type="text"
                            className="form-control"
                            id="line1"
                            name="line1"
                            value={line1}
                            onChange={handleChange}
                            placeholder="Enter address line 1"
                            autoFocus
                            required
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col">
                        <input
                            type="text"
                            className="form-control"
                            id="line2"
                            name="line2"
                            value={line2}
                            onChange={handleChange}
                            placeholder="Enter address line 2"
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className="col">
                        <input
                            type="text"
                            className="form-control"
                            id="city"
                            name="city"
                            value={city}
                            onChange={handleChange}
                            placeholder="Enter city"
                            required
                        />
                    </div>
                    <div className="col">
                        <select className="custom-select" onChange={handleChange} name="state_address" defaultValue={'DEFAULT'} required>
                            <option disabled value="DEFAULT">Select state...</option>
                            {States.map(state => (
                                <option key={`${state.state}`} value={`${state.state}`}>{`${state.state}`}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <CardElement />
                <div className="row">
                    <div className="col">
                        <small>I authorize Brit's Wine Inc. to send instructions to the financial institution that issued my card to take payments from my card account in accordance with the terms of my agreement with you.</small>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col"></div>
                    <div className="col text-right">
                        <button data-dismiss="modal" onClick={this.props.props.handleClose} className="canceling-button mr-2" data-toggle="modal" data-target="#addProductSetupModal">Back</button>
                        <button disabled={loading || !this.props.stripe} className="submit-button" id="save-card-button">{loading ? <div className="loader"></div> : "Save Card"}</button>
                    </div>
                </div>
            </form>
        );
    }
}

export default function InjectedCardSetupForm(props) {
    return (
        <ElementsConsumer>
            {({ stripe, elements }) => (
                <CardSetupForm stripe={stripe} elements={elements} props={props} />
            )}
        </ElementsConsumer>
    );
}