import React, { Component } from 'react';
import axios from 'axios';
import { isAuth } from '../../helpers/auth';
import { Redirect } from 'react-router-dom';

class Retaileractivation extends Component {
    constructor() {
        super()
        this.state = {
            token: '',
            show: true,
            errorAlert: '',
            requestedEmail: '',
            requestedRetailerName: '',
            retailerCreatedByEmailDomain: ''
        }
    }

    componentDidMount() {
        //     let token = match.params.token
        // to decode the token and pull out any user info from the token. Make sure to have the jwt required at the top.
        //     let { first_name } = jwt.decode(token)
        let token = this.props.match.params.token;
        if (token) {
            this.setState({ token: token })
        }

        // On componentDidMount creates the association record in the Partner_Account_Retailer table
        axios.post('/selfserve/api/retailer/activation', {
            token
        })
            .then(res => {
                console.log(res)
                this.setState({
                    requestedEmail: res.data.record[0].partnerAccountEmail,
                    requestedRetailerName: res.data.selected_unrelated_retailer_Name,
                    retailerCreatedByEmailDomain: res.data.emailDomain
                })
            })
            .catch(err => {
                console.log(err)
                this.setState({ errorAlert: err.response.data.errors })
            })
    }

    render() {
        const { requestedEmail, requestedRetailerName, retailerCreatedByEmailDomain } = this.state;

        // Handles removing error messages text onclick "x"
        const handleErrorAlert = e => {
            e.preventDefault()
            this.setState({ errorAlert: '' });
        }

        // onclick continue takes user to the home page
        const handleContinue = e => {
            e.preventDefault()
            this.props.history.push('/');
        }
        return (
            <div>
                {isAuth() ? null : <Redirect to='/login' />}
                <div className="container">

                    {/* This is for any alerts/errors */}
                    {(this.state.errorAlert) ?
                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                            {this.state.errorAlert}
                            <button type="button" className="close" onClick={handleErrorAlert} data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        : null}

                    <div>

                        <div className="row">
                            <div className="col text-center">
                                <img src="./../../images/Brits-Wine-Logo-100w.png" width="50px" className="mt-3 mb-3" alt="Brits Wine Logo" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col text-center">
                                <img src="./../../images/Retailer-Connected-Icon.png" width="600px" className="mt-3 mb-3" alt="Requested Retailer Icon" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-3 col-md-1"></div>
                            <div className="col-lg-6 col-md-10">
                                <div className="row">
                                    <div className="col text-center">
                                        <h4 className="mb-3">
                                            {(this.state.errorAlert) ?
                                            `${this.state.errorAlert}`
                                            : `${requestedEmail} successfully connected to ${requestedRetailerName} (${retailerCreatedByEmailDomain}) in Brit's Wine!`}
                                        </h4>
                                    </div>
                                </div>

                                {/* Continue button */}
                                <div className="row">
                                    <div className="col text-center mt-4">
                                        <p>Continue to view your dashboard</p>
                                        <button className="submit-button" onClick={handleContinue}>
                                            Continue
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-1"></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Retaileractivation;