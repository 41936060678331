import React, { Component } from 'react';
import { authenticate, isAuth } from '../../helpers/auth';
import axios from 'axios';
import jwt from 'jsonwebtoken';

class Activatecoworker extends Component {
    constructor() {
        super()
        this.state = {
            paramsToken: '',
            RetailerId: '',
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            isPasswordShown: false,
            hasNum: false,
            hasSpecial: false,
            hasMin: false,
            errorAlert: ''
        }
    }

    componentDidMount() {
        let paramsToken = this.props.match.params.token;
        let { coworkerData, RetailerId } = jwt.decode(paramsToken);
        let first_name = coworkerData.first_name;
        let last_name = coworkerData.last_name;
        let coworker_email = coworkerData.coworker_email;
        if (paramsToken) {
            this.setState({
                paramsToken: paramsToken,
                RetailerId: RetailerId,
                first_name: first_name,
                last_name: last_name,
                email: coworker_email
            })
        }

    }

    render() {

        const { email, first_name, last_name, password, isPasswordShown, hasNum, hasSpecial, hasMin, RetailerId, paramsToken } = this.state

        // token password visibility
        const togglePasswordVisibility = () => {
            const { isPasswordShown } = this.state;
            this.setState({ isPasswordShown: !isPasswordShown })
        }

        // Handle change from inputs
        const handlePasswordChange = e => {
            this.setState({ [e.target.name]: e.target.value })

            let containsNumber = e.target.value.match(/\d+/g);
            if (containsNumber !== null) {
                this.setState({ hasNum: true })
            } else {
                this.setState({ hasNum: false })
            }

            let containsSpecial = e.target.value.match(/[\s~`!@#$%&*+=\-\]\\';,/{}|\\":<>()_]/g);
            if (containsSpecial !== null) {
                this.setState({ hasSpecial: true })
            } else {
                this.setState({ hasSpecial: false })
            }

            if (e.target.value.length >= 10) {
                this.setState({ hasMin: true })
            } else {
                this.setState({ hasMin: false })
            }
        }

        // Handle remove error text onclick "x"
        const handleErrorAlert = e => {
            e.preventDefault()
            this.setState({ errorAlert: '' });
        }

        // Handle submit create partner account
        const handleSubmit = e => {
            e.preventDefault()
            if (first_name && last_name && email && password && RetailerId && paramsToken && hasNum && hasSpecial && hasMin) {
                axios.post('/selfserve/api/auth/coworkers/activate', {
                    paramsToken,
                    first_name,
                    last_name,
                    email,
                    password,
                    RetailerId
                })
                    .then(res => {
                        console.log(res);
                        authenticate(res, () => {

                        })
                        // if authenticate send to home page
                        if (isAuth()) {
                            // Direct the page to the private logged in home page.
                            this.props.history.push('/');
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        this.setState({ errorAlert: err.response.data.errors })
                    })
            }
        }

        return (
            <div>
                {/* {isAuth() ? <Redirect to='/' /> : null} */}
                <div className="container">

                    {/* This is for any alerts/errors */}
                    {(this.state.errorAlert) ?
                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                            {this.state.errorAlert}
                            <button type="button" className="close" onClick={handleErrorAlert} data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        : null}

                    <div className="row">
                        <div className="col-lg-3 col-md-1"></div>
                        <div className="col-lg-6 col-md-10">
                            <div className="register-form mt-5 pb-4">
                                {/* Title Section w/ Logo */}
                                <div className="row">
                                    <div className="col text-center">
                                        <img src="./../../images/Brits-Wine-Logo-200w.png" alt="Brits Wine Logo" width="100px" className="mt-3 mb-3" />
                                        <h4>
                                            Finish Your Registration
                                        </h4>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-2 col-md-1 col-sm-1"></div>
                                    <form className="col-lg-8 col-md-10 col-sm-10" onSubmit={handleSubmit}>
                                        <div className="form-row">
                                            {/* First Name Input */}
                                            <div className="col-lg">
                                                <div className="form-group">
                                                    <label>First Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter first name"
                                                        name="first_name"
                                                        value={first_name}
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                            {/* Last Name Input */}
                                            <div className="col-lg">
                                                <div className="form-group">
                                                    <label>Last Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter last name"
                                                        name="last_name"
                                                        value={last_name}
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        {/* Email Input */}
                                        <div className="form-row">
                                            <div className="col">
                                                <div className="form-group">
                                                    <label>Email</label>
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        placeholder="Enter work email"
                                                        name="email"
                                                        value={email}
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        {/* Password Input */}
                                        <div className="form-row">
                                            <div className="col">
                                                <div className="form-group">
                                                    <label>Password</label>
                                                    <input
                                                        type={(isPasswordShown) ? "text" : "password"}
                                                        className="form-control"
                                                        placeholder="Enter password"
                                                        name="password"
                                                        onChange={handlePasswordChange}
                                                        value={password}
                                                        maxLength="50"
                                                        autoFocus
                                                        required
                                                    />
                                                    <img className="password-icon"
                                                        src={(isPasswordShown) ? "./../../images/Eye-Icon-Hide.png" : "./../../images/Eye-Icon-Show.png"}
                                                        alt="Eye Icon"
                                                        width="25px"
                                                        onClick={togglePasswordVisibility}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        {/* Password Validations */}
                                        <div className="form-row text-center">
                                            <div className="col">
                                                <small className={hasMin ? "success" : null}>10 characters min</small>
                                            </div>
                                            <div className="col">
                                                <small className={hasSpecial ? "success" : null}>1 special character</small>
                                            </div>
                                            <div className="col">
                                                <small className={hasNum ? "success" : null}>1 number</small>
                                            </div>
                                        </div>

                                        {/* Strong password message */}
                                        <div className="form-row mt-1">
                                            <div className="col text-center">
                                                {(hasMin && hasSpecial && hasNum) ? <p className="success-complete">Strong password!</p> : null}
                                            </div>
                                        </div>

                                        {/* Terms agreement message */}
                                        <div className="form-row">
                                            <div className="col">
                                                <small>By clicking <strong>Register</strong>, you are indicating that you have read and agreed to the <a href="https://www.britswine.com/terms.html">terms of service</a>.</small>
                                            </div>
                                        </div>

                                        {/* Register Button */}
                                        <div className="form-row mt-4">
                                            <div className="col text-center">
                                                <button
                                                    type="submit"
                                                    className="submit-button"
                                                    disabled=
                                                    {first_name &&
                                                        last_name &&
                                                        email &&
                                                        hasMin &&
                                                        hasSpecial &&
                                                        hasNum ?
                                                        false : true}>
                                                    Register
                                                    </button>
                                            </div>
                                        </div>
                                    </form>
                                    <div className="col-lg-2 col-md-1 col-sm-1"></div>
                                </div>
                            </div>
                            <div className="text-center mt-3">
                                <small>Already have a Brit's Wine account? <a href='/login'>Login</a></small>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-1"></div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Activatecoworker;