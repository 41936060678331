import React from "react";
import "./style.css";

function Footer(props) {
    return (

        <footer>
            <div className="footer-container col-sm-12 col-md-12 col-lg-12">
                <div className="footer-links">
                    <small>
                        <ul>
                            <li><a href="https://www.britswine.com/terms.html">Terms</a></li>
                            <li>All Rights Reserved</li>
                            <li><a href="mailto:sales@britswine.com?Subject=Brits%20Wine%20Question">Contact</a></li>
                        </ul>
                    </small>
                </div>
            </div>
        </footer>
    );
}

export default Footer;