import React, { Component } from 'react';
import './style.css';
import { isAuth } from '../../helpers/auth';
import axios from 'axios';
import { Redirect } from 'react-router-dom';

class Updateprofile extends Component {
    constructor() {
        super()
        this.state = {
            first_name: '',
            email: '',
            errorAlert: '',
            title: '',
            file_name: '',
            file_public_id: '',
            image_preview_url: '',
            uploadPercentage: 0,
            password1: '',
            password2: '',
            current_password: '',
            isPasswordShown: false,
            hasNum: false,
            hasSpecial: false,
            hasMin: false
        }
    }


    componentDidMount() {
        if (localStorage.getItem('user')) {
            const user = localStorage.getItem('user');
            const email = JSON.parse(user).email;
            this.setState({ email: email });

            // Get sommelier information
            if (email) {
                axios.post('/selfserve/api/sommelier/profile/find', {
                    email
                })
                    .then(res => {
                        this.setState({
                            title: res.data.sommelier.title,
                            image_preview_url: res.data.sommelier.avatar
                        })
                    })
                    .catch(err => {
                        console.log(err)
                        this.setState({ errorAlert: err.response.data.errors })
                    })
            } else {
                console.log("We could not grab the email from the user info. It is possible this is because somehow you are not logged in. Try logging out and logging back in. If that does not work, contact sales@britswine.com")
                this.setState({ errorAlert: "We could not grab the email from the user info. It is possible this is because somehow you are not logged in. Try logging out and logging back in. If that does not work, contact sales@britswine.com" })
            }
        }
    }

    render() {
        const { email, title, file_name, image_preview_url, uploadPercentage, password1, password2, current_password, isPasswordShown, hasNum, hasSpecial, hasMin } = this.state

        // Handle change from inputs
        const handleChange = e => {
            this.setState({ [e.target.name]: e.target.value })
        }

        // Handle File upload
        const handleFileUpload = e => {
            e.preventDefault()
            console.log(e.target.files[0]);
            let file_type_png = e.target.files[0].type.toLowerCase().includes("png");
            let file_type_jpeg = e.target.files[0].type.toLowerCase().includes("jpeg");
            let file_type_jpg = e.target.files[0].type.toLowerCase().includes("jpg");
            let file_size = e.target.files[0].size;

            if (file_type_png || file_type_jpeg || file_type_jpg) {
                if (file_size <= 1000000) {
                    var file = e.target.files[0];
                    var formData = new FormData();
                    formData.append('file', file);

                    axios({
                        url: '/selfserve/api/sommelier/image/upload',
                        method: 'POST',
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        data: formData,
                        onUploadProgress: (progressEvent) => {
                            let percent = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                            this.setState({ uploadPercentage: percent })
                        }
                    })
                        .then(res => {
                            this.setState({
                                image_preview_url: res.data.image.secure_url,
                                file_name: file.name,
                                file_public_id: res.data.image.public_id,
                                uploadPercentage: 0
                            })
                        })
                        .catch(err => {
                            console.log(err)
                            this.setState({ errorAlert: err.response.data.errors })
                        });
                } else {
                    this.setState({ errorAlert: "The file size you uploaded needs to be less than 1mb" })
                }
            } else {
                this.setState({ errorAlert: "The file format you uploaded needs to be a png, jpeg or jpg" })
            }
        }

        // Delete the profile picture from state and cloudinary
        const handleRemoveFile = e => {
            e.preventDefault()
            axios.post('/selfserve/api/sommelier/image/destroy', {
                image_preview_url
            })
                .then(res => {
                    this.setState({
                        image_preview_url: '',
                        file_name: '',
                        file_public_id: ''
                    })
                })
                .catch(err => {
                    console.log(err);
                    this.setState({ errorAlert: err.response.data.errors })
                })
        }

        // Handle toggle password visibility
        const togglePasswordVisibility = () => {
            const { isPasswordShown } = this.state;
            this.setState({ isPasswordShown: !isPasswordShown })
        }

        // Handle change from inputs
        const handleNewPasswordChange = e => {
            this.setState({ [e.target.name]: e.target.value })

            let containsNumber = e.target.value.match(/\d+/g);
            if (containsNumber !== null) {
                this.setState({ hasNum: true })
            } else {
                this.setState({ hasNum: false })
            }

            let containsSpecial = e.target.value.match(/[\s~`!@#$%&*+=\-\]\\';,/{}|\\":<>()_]/g);
            if (containsSpecial !== null) {
                this.setState({ hasSpecial: true })
            } else {
                this.setState({ hasSpecial: false })
            }

            if (e.target.value.length >= 10) {
                this.setState({ hasMin: true })
            } else {
                this.setState({ hasMin: false })
            }
        }

        // Handle change from inputs
        const handleConfirmPasswordChange = e => {
            this.setState({ [e.target.name]: e.target.value })
        }

        // Handle update the profile
        const handleUpdateProfile = e => {
            e.preventDefault()
            // Check to see if the email, title and image exist
            if (email && title && image_preview_url) {
                // if the above fields are filled then check to see if current password or password1 or password2 are empty values
                if ((current_password || password1 || password2) === '') {
                    // send normal post request without password data
                    console.log("Handler connected! Not updating the password.")
                    axios.put('/selfserve/api/sommelier/update', {
                        email, title, image_preview_url
                    })
                        .then(res => {
                            this.props.history.push('/account');
                        })
                        .catch(err => {
                            console.log(err)
                            this.setState({ errorAlert: err.response.data.errors })
                        })
                } else {
                    // Otherwise, that means the user wants to update the password. If we have the current password filled
                    if (current_password) {
                        // If the current password does not equal the new password
                        if (current_password !== password1) {
                            // If the new password matches the confirm password and the new password and confirm password both exist
                            if ((password1 === password2) && password2 && password1) {
                                // If the password passes the validation requirements
                                if (hasMin && hasSpecial && hasNum) {
                                    console.log("Handler connected! We are updating the password.");
                                    // Send post request with password data
                                    axios.put('/selfserve/api/sommelier/user/update', {
                                        email, title, image_preview_url, current_password, password2
                                    })
                                        .then(res => {
                                            this.props.history.push('/account');
                                        })
                                        .catch(err => {
                                            console.log(err)
                                            this.setState({ errorAlert: err.response.data.errors })
                                        })
                                } else {
                                    console.log("The new password and confirm password need to include a minimum of 10 characters, at least 1 special character and 1 number");
                                    this.setState({ errorAlert: "The new password and confirm password need to include a minimum of 10 characters, at least 1 special character and 1 number" })
                                }
                            } else {
                                console.log("The new password needs to match with the confirm password and both fields need to be filled in");
                                this.setState({ errorAlert: "The new password needs to match with the confirm password and both fields need to be filled in" })
                            }
                        } else {
                            console.log("The current password cannot match the new password. Please try again")
                            this.setState({ errorAlert: "The current password cannot match the new password. Please try again" })
                        }
                    } else {
                        console.log("You need to add the current password")
                        this.setState({ errorAlert: "You need to add the current password" })
                    }
                }
            } else {
                console.log("You need to add your position title and/or profile picture")
                this.setState({ errorAlert: "You need to add your position title and profile picture" })
            }
        }

        // Handle cancel button - sends user back to the account page
        const handleCancel = e => {
            e.preventDefault()
            this.props.history.push('/account');
        }

        // Handle remove error text in error alert onclick "x"
        const handleErrorAlert = e => {
            e.preventDefault()
            this.setState({ errorAlert: '' });
        }

        return (
            <div>
                {isAuth() ? null : <Redirect to='/login' />}
                <div className="container">

                    {/* This is for any alerts/errors */}
                    {(this.state.errorAlert) ?
                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                            {this.state.errorAlert}
                            <button type="button" className="close" onClick={handleErrorAlert} data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        : null}

                    <div className="row">
                        <div className="col-lg">
                            {/* Title Section w/ logo */}
                            <div className="row">
                                <div className="col text-center">
                                    <img src="./../../images/Brits-Wine-Logo-100w.png" alt="Brits Wine Logo" width="50px" className="mt-3 mb-4" />
                                    <h4>
                                        Edit Profile
                                    </h4>
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-lg-1 col-md-1"></div>
                                <div className="col-lg-5 col-md-10">
                                    <div className="mt-3 pb-4">
                                        <div className="row">
                                            <div className="col-lg-1 col-md-1 col-sm-1"></div>
                                            <form className="col-lg-10 col-md-10 col-sm-10">
                                                {/* Title input */}
                                                <div className="form-row">
                                                    <div className="col-lg">
                                                        <div className="form-group">
                                                            <label>Title</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Enter job title"
                                                                name="title"
                                                                onChange={handleChange}
                                                                value={title}
                                                                autoFocus
                                                                required
                                                            />
                                                            <small>Enter your professional role ex. Head Sommelier</small>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Profile Picture Input */}
                                                <div className="form-row">
                                                    <div className="col-lg">
                                                        <div className="form-group">
                                                            <label htmlFor="file">Profile Picture</label>
                                                            <div className="file-input-wrapper">
                                                                <div className="row">
                                                                    <div className="col-8 pt-2">
                                                                        {file_name ?
                                                                            <p className="file-selected-true">{file_name}</p>
                                                                            : <p className="file-selected-false">No file selected</p>
                                                                        }
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <input
                                                                            type="file"
                                                                            id="file"
                                                                            name="file"
                                                                            onChange={handleFileUpload}
                                                                            className="file-input-button"
                                                                            required
                                                                        />
                                                                        <label htmlFor="file">Add File</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <small>Files maximum 1mb, formats: jpeg, jpg, png</small>
                                                        </div>

                                                    </div>
                                                </div>

                                                {/* Progress Bar */}
                                                {uploadPercentage > 0 ?
                                                    <div className="form-row mb-2">
                                                        <div className="col-lg">
                                                            <div className="progress">
                                                                <div className="progress-bar progress-bar-striped bg-info progress-bar-animated"
                                                                    role="progressbar"
                                                                    aria-valuenow={uploadPercentage}
                                                                    aria-valuemin="0"
                                                                    aria-valuemax="100"
                                                                    style={{ width: `${uploadPercentage}%` }}></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null}

                                                {/* Image preview thumbnail */}
                                                <div className="form-row">
                                                    <div className="col-lg">
                                                        <div className="form-group profile-picture-thumbnail">
                                                            {image_preview_url ?
                                                                <div>
                                                                    <img src={image_preview_url} alt="Profile Preview Thumbnail" />
                                                                    <button onClick={handleRemoveFile}><span>&times;</span></button>
                                                                </div>
                                                                : <p>No file selected</p>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                            <div className="col-lg-1 col-md-1 col-sm-1"></div>
                                        </div>
                                    </div>
                                </div>

                                {/* Change password section */}
                                <div className="col-lg-5 col-md-1">
                                    <div className="row mt-3 reset-password">
                                        <div className="col-lg">
                                            <h5>Change Password</h5>

                                            {/* Current Password Input */}
                                            <div className="form-row mt-3">
                                                <div className="col">
                                                    <div className="form-group">
                                                        <label>Current Password</label>
                                                        <input
                                                            type={(isPasswordShown) ? "text" : "password"}
                                                            className="form-control"
                                                            placeholder="Enter current password"
                                                            name="current_password"
                                                            onChange={handleChange}
                                                            value={current_password}
                                                            maxLength="50"
                                                            required
                                                        />
                                                        <img className="password-icon"
                                                            src={(isPasswordShown) ? "./../../../images/Eye-Icon-Hide.png" : "./../../../images/Eye-Icon-Show.png"}
                                                            alt="Eye Icon"
                                                            width="25px"
                                                            onClick={togglePasswordVisibility}
                                                        />
                                                        <small><a href="/users/password/forgot">forgot password?</a></small>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* New Password Input */}
                                            <div className="form-row">
                                                <div className="col">
                                                    <div className="form-group">
                                                        <label>New Password</label>
                                                        <input
                                                            type={(isPasswordShown) ? "text" : "password"}
                                                            className="form-control"
                                                            placeholder="Enter new password"
                                                            name="password1"
                                                            onChange={handleNewPasswordChange}
                                                            value={password1}
                                                            maxLength="50"
                                                            required
                                                        />
                                                        <img className="password-icon"
                                                            src={(isPasswordShown) ? "./../../../images/Eye-Icon-Hide.png" : "./../../../images/Eye-Icon-Show.png"}
                                                            alt="Eye Icon"
                                                            width="25px"
                                                            onClick={togglePasswordVisibility}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Password Validation */}
                                            <div className="form-row text-center">
                                                <div className="col">
                                                    <small className={this.state.hasMin ? "success" : null}>10 characters min</small>
                                                </div>
                                                <div className="col">
                                                    <small className={this.state.hasSpecial ? "success" : null}>1 special character</small>
                                                </div>
                                                <div className="col">
                                                    <small className={this.state.hasNum ? "success" : null}>1 number</small>
                                                </div>
                                            </div>

                                            {/* Strong Password Message */}
                                            <div className="form-row mt-1">
                                                <div className="col text-center">
                                                    {(this.state.hasMin && this.state.hasSpecial && this.state.hasNum) ? <p className="success-complete">Strong password!</p> : null}
                                                </div>
                                            </div>

                                            {/* Confirm Password Input */}
                                            <div className="form-row mt-3">
                                                <div className="col">
                                                    <div className="form-group">
                                                        <label>Confirm Password</label>
                                                        <input
                                                            type={(isPasswordShown) ? "text" : "password"}
                                                            className="form-control"
                                                            placeholder="Confirm password"
                                                            name="password2"
                                                            onChange={handleConfirmPasswordChange}
                                                            value={password2}
                                                            maxLength="50"
                                                            required
                                                        />
                                                        <img className="password-icon"
                                                            src={(isPasswordShown) ? "./../../../images/Eye-Icon-Hide.png" : "./../../../images/Eye-Icon-Show.png"}
                                                            alt="Eye Icon"
                                                            width="25px"
                                                            onClick={togglePasswordVisibility}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Password Match Message */}
                                            <div className="form-row mt-1">
                                                <div className="col text-center">
                                                    {((hasMin && hasSpecial && hasNum) && (password1 === password2)) ? <p className="success-complete">Passwords match!</p> : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1 col-md-1"></div>
                            </div>

                            <div className="row">
                                <div className="col-lg-8"></div>
                                <div className="col-lg-4">
                                    <div className="row">
                                        <div className="col-lg-5"></div>
                                        {/* Cancel Button */}
                                        <div className="col-lg-3 text-left">
                                            <button
                                                type="submit"
                                                className="canceling-button"
                                                onClick={handleCancel}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                        {/* Update Button */}
                                        <div className="col-lg-4 text-right">
                                            <button
                                                type="submit"
                                                className="submit-button"
                                                onClick={handleUpdateProfile}
                                                disabled=
                                                {image_preview_url &&
                                                    title ?
                                                    false : true}
                                            >
                                                Update
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

export default Updateprofile;