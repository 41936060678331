import React, { Component } from 'react';
import './style.css';
import { isAuth, signout } from '../../helpers/auth';
import axios from 'axios';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
import Navbar from './../../components/Navbar/index';
import Footer from './../../components/Footer/index';
import CardSetupForm from './../../components/Cardsetupform/index';
import CardSetupeditForm from './../../components/Cardsetupeditform/index';
import Modal from 'react-bootstrap/Modal'

class Account extends Component {
    constructor() {
        super()
        this.state = {
            first_name: '',
            last_name: '',
            email: '',
            avatar: '',
            title: '',
            created: '',
            errorAlert: '',
            hasBillingInfo: '',
            line1: '',
            line2: '',
            city: '',
            state_address: '',
            postal_code: '',
            card_brand: '',
            exp_month: '',
            exp_year: '',
            last4: '',
            modal: false,
            Editmodal: false,
        }
    }

    componentDidMount() {
        if (localStorage.getItem('user')) {
            const user = localStorage.getItem('user');
            const first_name = JSON.parse(user).first_name;
            const last_name = JSON.parse(user).last_name;
            const email = JSON.parse(user).email;
            const created = JSON.parse(user).createdAt;
            const formattedCreated = moment(created).format('MMMM Do YYYY');
            this.setState({
                first_name: first_name,
                last_name: last_name,
                email: email,
                created: formattedCreated
            });

            if (email) {
                // find the profile information about the user and store it in state
                axios.post('/selfserve/api/sommelier/profile/find', {
                    email
                })
                    .then(res => {
                        this.setState({ hasBillingInfo: res.data.hasBillingInfo })
                        if (res.data.sommelier) {
                            let date = moment(res.data.sommelier.partner_account.createdAt).format('MMMM Do YYYY');
                            this.setState({
                                first_name: res.data.sommelier.partner_account.first_name,
                                last_name: res.data.sommelier.partner_account.last_name,
                                email: res.data.sommelier.partner_account.email,
                                avatar: res.data.sommelier.avatar,
                                title: res.data.sommelier.title,
                                created: date
                            })
                        }
                        if (res.data.hasBillingInfo === true) {
                            console.log(res.data.billingInfo)
                            console.log(res.data.billingInfo.invoice_settings.default_payment_method.billing_details.address.line1)
                            this.setState({
                                line1: res.data.billingInfo.invoice_settings.default_payment_method.billing_details.address.line1,
                                line2: res.data.billingInfo.invoice_settings.default_payment_method.billing_details.address.line2,
                                city: res.data.billingInfo.invoice_settings.default_payment_method.billing_details.address.city,
                                state_address: res.data.billingInfo.invoice_settings.default_payment_method.billing_details.address.state,
                                postal_code: res.data.billingInfo.invoice_settings.default_payment_method.billing_details.address.postal_code,
                                card_brand: res.data.billingInfo.invoice_settings.default_payment_method.card.brand,
                                exp_month: res.data.billingInfo.invoice_settings.default_payment_method.card.exp_month,
                                exp_year: res.data.billingInfo.invoice_settings.default_payment_method.card.exp_year,
                                last4: res.data.billingInfo.invoice_settings.default_payment_method.card.last4
                            })
                        }
                    })
                    .catch(err => {
                        console.log(err)
                        this.setState({ errorAlert: err.response.data.errors })
                    })
            }
        }
    }

    render() {
        const { email, first_name, last_name, avatar, title, created, hasBillingInfo, modal, line1, line2, city, state_address, postal_code, card_brand, exp_month, exp_year, last4, Editmodal } = this.state

        // Handle remove error text for alert banner onclick "x"
        const handleErrorAlert = e => {
            e.preventDefault()
            this.setState({ errorAlert: '' });
        }

        // Take the user to the edit profile page
        const handleEditProfile = e => {
            e.preventDefault()
            this.props.history.push('/sommelier/profile/edit');
        }

        // If the user has not created a sommelier profile yet, this onclicl will take the user to the create sommelier page
        const handleCreateProfile = e => {
            e.preventDefault()
            this.props.history.push('/sommelier/create')
        }

        // Handle delete the partner account
        const handleDeleteAccount = e => {
            e.preventDefault()
            // If we have the email, delete the user and everything it is associated with. Then send the user to the login page
            if (email) {
                axios.post('/selfserve/api/auth/user/delete', {
                    email, avatar
                })
                    .then(res => {
                        signout(() => {
                            this.props.history.push('/login');
                        })
                    })
                    .catch(err => {
                        console.log(err)
                        this.setState({ errorAlert: err.response.data.errors })
                    })
            } else {
                this.setState({ errorAlert: "We were unable to grab the email from the logged in user. This probably means that you are not logged in. Please logout and then login again. If that does not work, please contact sales@britswine.com" })
            }
        }

        const handleShow = e => {
            this.setState({ modal: true })
        }

        const handleClose = e => {
            this.setState({ modal: false })
        }

        const handleUploadProducts = e => {
            handleClose()
            window.location.reload()
        }

        const handleEditClose = e => {
            this.setState({ Editmodal: false })
        }

        const handleEditBilling = e => {
            this.setState({ Editmodal: true })
        }

        const handleFinishEditBilling = e => {
            handleClose()
            window.location.reload()
        }

        // Handle signout process
        const handleSignOut = e => {
            signout(() => {
                this.props.history.push('/login');
            })
        }

        return (
            <div>
                {isAuth() ? null : <Redirect to='/login' />}
                <Navbar handleSignOut={handleSignOut}></Navbar>
                <div className="container">

                    {/* This is for any alerts/errors */}
                    {(this.state.errorAlert) ?
                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                            {this.state.errorAlert}
                            <button type="button" className="close" onClick={handleErrorAlert} data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        : null}

                    {/*The Credit Card Info modal */}
                    <Modal show={modal} onHide={handleClose} centered={true}>
                        <Modal.Header closeButton>
                            <h4>Add Credit Card Details</h4>
                        </Modal.Header>
                        <Modal.Body>
                            <CardSetupForm
                                handleUploadProducts={handleUploadProducts}
                                handleClose={handleClose}
                            />
                        </Modal.Body>
                    </Modal>

                    {/*The Credit Card EDIT Info modal */}
                    <Modal show={Editmodal} onHide={handleEditClose} centered={true}>
                        <Modal.Header closeButton>
                            <h4>Add Credit Card Details</h4>
                        </Modal.Header>
                        <Modal.Body>
                            <CardSetupeditForm
                                line1={line1}
                                line2={line2}
                                city={city}
                                state_address={state_address}
                                postal_code={postal_code}
                                exp_month={exp_month}
                                exp_year={exp_year}
                                handleFinishEditBilling={handleFinishEditBilling}
                                handleClose={handleEditClose}
                            />
                        </Modal.Body>
                    </Modal>

                    {/*The modal */}
                    <div>
                        <div className="modal fade" id="myModal" role="dialog">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title">Are You Sure?</h4>
                                        <button data-dismiss="modal" aria-label="Close" type="button" className="close"><span aria-hidden="true">&times;</span></button>
                                    </div>
                                    <div className="modal-body">
                                        <p>Are you sure you would like to delete your account?</p>
                                        <small>Deleting your account is permanent. Do not continue unless you are completely sure you would like to proceed.</small>
                                    </div>
                                    <div className="modal-footer">
                                        <button data-dismiss="modal" aria-label="Close" type="button" className="canceling-button">Cancel</button>
                                        <button data-dismiss="modal" aria-label="Close" type="button" className="submit-button" onClick={handleDeleteAccount}>Delete</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg text-center">
                            <h4>Account</h4>
                        </div>
                    </div>

                    <div className="row mb-2">
                        <div className="col-lg-3">
                            <p>Hi, {first_name}</p>
                        </div>
                        <div className="col-lg-5"></div>
                        <div className="col-lg-4 text-right">
                            <p>Account holder since {created}</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-8">
                            <div className="row account-info">
                                <div className="col-lg">
                                    <div className="row">
                                        <div className="col-lg text-center mb-3">
                                            <h5>Account Info</h5>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-4 profile-pic">
                                            {avatar ?
                                                <img src={avatar} alt="Profile Avatar" />
                                                :
                                                null
                                            }
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="row">
                                                <div className="col-lg">
                                                    <strong><label>Name</label></strong><br></br>
                                                    <div className="tool-tip">
                                                        <p>{first_name} {last_name} <small>&#9432;</small></p>
                                                        <span className="tool-tip-text">Your name cannot be edited</span>
                                                    </div>
                                                </div>
                                            </div>

                                            {title ?
                                                <div className="row">
                                                    <div className="col-lg">
                                                        <strong><label>Title</label></strong>
                                                        <p>{title}</p>
                                                    </div>
                                                </div>
                                                : null}
                                        </div>
                                        <div className="col-lg-4">
                                            <strong><label>Email</label></strong><br></br>
                                            <div className="tool-tip">
                                                <p>{email} <small>&#9432;</small></p>
                                                <span className="tool-tip-text">Your email cannot be edited</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-6"></div>
                                        <div className="col-lg-3">
                                            {title ?
                                                <button className="edit mt-1" onClick={handleEditProfile}>Edit Profile</button>
                                                :
                                                <button className="submit-button" onClick={handleCreateProfile}>Create Profile</button>
                                            }
                                        </div>
                                        <div className="col-lg-3">
                                            <button
                                                className="remove mt-1"
                                                data-toggle="modal"
                                                data-target="#myModal"
                                            >Delete Account</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row billing-info mt-5">
                                <div className="col-lg">
                                    {hasBillingInfo ?
                                        <div>
                                            <div className="row">
                                                <div className="col">
                                                    <h5>Billing Info</h5>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-5">
                                                    <div className="row">
                                                        <div className="col">
                                                            <strong>Address</strong>
                                                            <p>{line1}</p>
                                                            <p>{line2}</p>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <p>{city}, {state_address} {postal_code}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-5">
                                                    <strong>Card</strong>
                                                    <p>Brand: {card_brand}</p>
                                                    <p>Exp: {exp_month}/{exp_year}</p>
                                                    <p>Last 4: {last4}</p>
                                                </div>
                                                <div className="col-2">
                                                    <button className="edit" onClick={handleEditBilling}>Edit</button>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="text-center">
                                            <h5>Billing Info</h5>
                                            <button className="submit-button mt-4" onClick={handleShow}>Add Billing Details</button>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2"></div>
                    </div>

                    <div className="row mt-2">
                        <div className="col-lg-11"></div>
                        <div className="col-lg-1">
                            <a href="/"><button className="submit-button">Home</button></a>
                        </div>
                    </div>
                </div>
                <Footer></Footer>
            </div>
        )
    }
}

export default Account;