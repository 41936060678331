import React, { Component } from 'react';

class Registersent extends Component {
    constructor() {
        super()
        this.state = {
            email: ''
        }
    }

    componentDidMount() {
        // this is not ideal as a way to get this information, though it does work. Because the user doesnt exist and therefore is not logged in,
        // there is no way to grab this from the localstorage or pull it from the DB
        this.setState({ email: sessionStorage.getItem("email") })
    };

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-1"></div>
                    <div className="col-lg-6 col-md-10">
                        <div className="register-form mt-5 p-5">
                            {/* Title section w/ logo */}
                            <div className="row">
                                <div className="col text-center">
                                    <img src="./../../images/Brits-Wine-Logo-100w.png" width="50px" className="mt-3 mb-3" alt="Brits Wine Logo" />
                                </div>
                            </div>

                            {/* Information section */}
                            <div className="row">
                                <div className="col text-center">
                                    <img src="./../../images/Email-Sent-Icon.png" width="350px" className="mt-3 mb-3" alt="Email Sent Icon" />
                                    <h1 className="mb-3">Check your email!</h1>
                                    <h4 className="mb-3">You need to confirm your account</h4>
                                    <p>We've sent a verification email to <strong>{this.state.email}</strong>. Follow the instructions in the email to get started!</p>
                                    <small>The email will expire in 24 hours</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-1"></div>
                </div>
            </div>
        )
    }
}

export default Registersent;