import React from 'react';
import { ElementsConsumer, CardElement } from '@stripe/react-stripe-js';
import axios from 'axios';
import States from './../../states.json';
// Super helpful docs for setting up stripe setupintent and paymentintent:
// https://stripe.com/docs/payments/save-and-reuse#web-setup

class Cardsetupeditform extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            States,
            line1: this.props.props.line1,
            line2: this.props.props.line2,
            city: this.props.props.city,
            state_address: this.props.props.state_address,
            full_name: '',
            email: '',
            loading: false,
            cardfilled: false,
        }
    }

    componentDidMount() {
        if (localStorage.getItem('user')) {
            const user = localStorage.getItem('user');
            const first_name = JSON.parse(user).first_name;
            const last_name = JSON.parse(user).last_name;
            const full_name = first_name + " " + last_name;
            const email = JSON.parse(user).email;
            this.setState({
                full_name: full_name,
                email: email
            });
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const { email } = this.state;
        this.setState({ loading: true })

        const { stripe, elements } = this.props

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make  sure to disable form submission until Stripe.js has loaded.
            return;
        }

        stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
            billing_details: {
                address: {
                    city: this.state.city,
                    line1: this.state.line1,
                    line2: this.state.line2,
                    state: this.state.state_address,
                },
                name: this.state.full_name,
                email: this.state.email,
            },
        }).then(res => {
            console.log(res)
            console.log(res.paymentMethod)
            console.log(res.paymentMethod.id)
            let paymentMethod = res.paymentMethod.id;
            axios.post('/selfserve/api/auth/card/update', {
                email, paymentMethod
            }).then(res => {
                console.log(res.data)
                this.setState({ loading: false })
                this.props.props.handleFinishEditBilling()
            }).catch(err => {
                console.log(err)
                this.setState({ loading: false })
            })
        }).catch(err => {
            console.log(err)
            this.setState({ loading: false })
        })
    };

    render() {
        const { line1, line2, city, States, loading, state_address, cardfilled } = this.state;

        // Handle change from inputs
        const handleChange = e => {
            this.setState({ [e.target.name]: e.target.value })
        }

        const handleCardChange = event => {
            if (event.complete) {
                // enable payment button
                this.setState({ cardfilled: true })
            } else {
                // show validation to customer
                this.setState({ cardfilled: false })
            }
        }

        return (
            <form onSubmit={this.handleSubmit}>
                <h5>Billing Details</h5>
                <div className="form-row">
                    <div className="form-group col">
                        <input
                            type="text"
                            className="form-control"
                            id="line1"
                            name="line1"
                            value={line1}
                            onChange={handleChange}
                            placeholder="Enter address line 1"
                            autoFocus
                            required
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col">
                        <input
                            type="text"
                            className="form-control"
                            id="line2"
                            name="line2"
                            value={line2}
                            onChange={handleChange}
                            placeholder="Enter address line 2"
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className="col">
                        <input
                            type="text"
                            className="form-control"
                            id="city"
                            name="city"
                            value={city}
                            onChange={handleChange}
                            placeholder="Enter city"
                            required
                        />
                    </div>
                    <div className="col">
                        <select className="custom-select" onChange={handleChange} name="state_address" value={state_address} required>
                            <option disabled value="DEFAULT">Select state...</option>
                            {States.map(state => (
                                <option key={`${state.state}`} value={`${state.state}`}>{`${state.state}`}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <CardElement onChange={handleCardChange} />
                <div className="row">
                    <div className="col">
                        <small>I authorize Brit's Wine Inc. to send instructions to the financial institution that issued my card to take payments from my card account in accordance with the terms of my agreement with you.</small>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col"></div>
                    <div className="col text-right">
                        <button data-dismiss="modal" onClick={this.props.props.handleClose} className="canceling-button mr-2" data-toggle="modal" data-target="#addProductSetupModal">Back</button>
                        <button disabled={loading || !this.props.stripe || !line1 || !city || !state_address || !cardfilled} className="submit-button" id="save-card-button">{loading ? <div className="loader"></div> : "Save Card"}</button>
                    </div>
                </div>
            </form>
        );
    }
}

export default function InjectedCardSetupForm(props) {
    return (
        <ElementsConsumer>
            {({ stripe, elements }) => (
                <Cardsetupeditform stripe={stripe} elements={elements} props={props} />
            )}
        </ElementsConsumer>
    );
}