import React from "react";
import "./style.css";
import { isAuth } from '../../helpers/auth';

function Navbar(props) {
    return (
        <div className="navigation-bar">
            <ul>
                {isAuth() ?
                    <div>
                        <li className="logo">
                            <a href="/">
                                <img src="./../../images/Brits-Wine-Logo-100w.png" alt="Brits Wine Logo" />
                            </a>
                        </li>

                        <div className="links">
                            <a href="/account">
                                <li>
                                    <img src="./../../images/Account-Icon.png" alt="Account Icon" />
                                    Account
                                </li>
                            </a>
                            <li>
                                <button onClick={props.handleSignOut}>
                                    <img src="./../../images/Logout-Icon.png" alt="Logout Icon" />
                                    Logout
                                </button>
                            </li>
                        </div>
                    </div>
                    :
                    <li className="logo">
                        <a href="/login">
                            <img src="./../../images/Brits-Wine-Logo-100w.png" alt="Brits Wine Logo" />
                        </a>
                    </li>
                }
            </ul>
        </div>
    );
}

export default Navbar;