import React from "react";

function Coworkerinput(props) {
    return (
        <div className="row">
            <div className="col-lg-3">
                <div className="form-row">
                    <div className="col">
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter first name"
                                name={`first_name${props.id}`}
                                onChange={props.handleChange}
                                required
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-lg-3">
                <div className="form-row">
                    <div className="col">
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter last name"
                                name={`last_name${props.id}`}
                                onChange={props.handleChange}
                                required
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-lg-5">
                <div className="form-row">
                    <div className="col">
                        <div className="form-group">
                            <input
                                type="email"
                                className="form-control"
                                placeholder="Enter coworker's email"
                                name={`coworker_email${props.id}`}
                                onChange={props.handleChange}
                                required
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-lg-1" onClick={() => props.removeInput(props.id)}>
                <span style={{fontSize:"25px", cursor: "pointer"}}>&times;</span>
            </div>
        </div>
    );
}

export default Coworkerinput;