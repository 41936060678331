import React, { Component } from 'react';
import { isAuth } from '../../helpers/auth';
import { Redirect } from 'react-router-dom';

class Joinsent extends Component {
    constructor() {
        super()
        this.state = {
            Retailer_Name: '',
            Domain: ''
        }
    }

    componentDidMount() {
        if (localStorage.getItem('user')) {
            // Set the domain and Retailer name from the session storage in state to display on the page
            this.setState({
                Retailer_Name: sessionStorage.getItem("Retailer_Name"),
                Domain: sessionStorage.getItem("Domain")
            })
        }
    };

    render() {
        // This just handles when user clicks the continue button to be taken to the create sommelier page
        const handleContinue = e => {
            e.preventDefault()
            sessionStorage.removeItem("Retailer_Name");
            sessionStorage.removeItem("Domain");
            this.props.history.push('/sommelier/create');
        }

        return (
            <div>
                {isAuth() ? null : <Redirect to='/login' />}
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-1"></div>
                        <div className="col-lg-6 col-md-10">
                            <div className="p-3">
                                <div className="row">
                                    <div className="col text-center">
                                        <img src="./../../images/Brits-Wine-Logo-100w.png" width="50px" className="mt-3 mb-3" alt="Brits Wine Logo" />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col text-center">
                                        <img src="./../../images/Requested-Retailer-Icon.png" width="200px" className="mt-3 mb-3" alt="Requested Retailer Icon" />
                                        <h4 className="mb-3">You've requested to join {this.state.Retailer_Name} ({this.state.Domain}) in Brit's Wine!</h4>
                                        <p>We've sent a confirmation email to your Brit's Wine admin. Once they approve your request, you'll receive an email from us letting you know!</p>
                                        <small>The request will expire in 1 week</small>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col text-center mt-4">
                                        <p>Continue to fill out your sommelier profile information</p>
                                        <button className="submit-button" onClick={handleContinue}>
                                            Continue
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-1"></div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Joinsent;