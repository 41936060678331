import React, { Component } from 'react';
import { isAuth } from '../../helpers/auth';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import Inventory_Systems from '../../inventory_systems.json';

class Editretailer extends Component {
    constructor() {
        super()
        this.state = {
            email: '',
            errorAlert: '',
            RetailerId: '',
            retailer_name: '',
            display_name: '',
            sales_email: '',
            file_name: '',
            file_public_id: '',
            image_preview_url: '',
            uploadPercentage: 0,
            other_pos: '',
            Inventory_Systems
        }
    }

    componentDidMount() {
        if (localStorage.getItem('user')) {
            const user = localStorage.getItem('user');
            const email = JSON.parse(user).email;
            this.setState({ email: email });

            let RetailerId = this.props.match.params.id;
            if (RetailerId) {
                this.setState({ RetailerId: RetailerId })

                // Grab the retailer data associated with the retailer id from the url params
                axios.post('/selfserve/api/retailer/find/one', {
                    RetailerId
                })
                    .then(res => {
                        // Set the input field values to that of the values from the DB record
                        this.setState({
                            retailer_name: res.data.retailer.name,
                            display_name: res.data.retailer.display_name,
                            sales_email: res.data.retailer.sales_email,
                            image_preview_url: res.data.retailer.logo,
                            file_public_id: res.data.retailer.logo,
                            other_pos: res.data.retailer.other_pos
                        })
                    })
                    .catch(err => {
                        console.log(err)
                        this.setState({ errorAlert: err.response.data.errors })
                    })
            } else {
                this.setState({ errorAlert: "There is no retailer id from the url" })
            }
        }
    }


    render() {
        const { RetailerId, other_pos, sales_email, display_name, email, retailer_name, file_name, image_preview_url, uploadPercentage, Inventory_Systems } = this.state

        // Handle change from inputs
        const handleChange = e => {
            this.setState({ [e.target.name]: e.target.value })
        }

        // Handle file upload process to cloudinary and state
        const handleFileUpload = e => {
            e.preventDefault()
            console.log(e.target.files[0]);
            let file_type_png = e.target.files[0].type.toLowerCase().includes("png");
            let file_type_jpeg = e.target.files[0].type.toLowerCase().includes("jpeg");
            let file_type_jpg = e.target.files[0].type.toLowerCase().includes("jpg");
            let file_size = e.target.files[0].size;

            if (file_type_png || file_type_jpeg || file_type_jpg) {
                if (file_size <= 1000000) {
                    var file = e.target.files[0];
                    var formData = new FormData();
                    formData.append('file', file);

                    axios({
                        url: '/selfserve/api/retailer/logo/upload',
                        method: 'POST',
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        data: formData,
                        onUploadProgress: (progressEvent) => {
                            let percent = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                            this.setState({ uploadPercentage: percent })
                        }
                    })
                        .then(res => {
                            this.setState({
                                image_preview_url: res.data.image.secure_url,
                                file_name: file.name,
                                file_public_id: res.data.image.public_id,
                                uploadPercentage: 0
                            })
                        })
                        .catch(err => {
                            this.setState({ errorAlert: err.response.data.errors })
                        });
                } else {
                    this.setState({ errorAlert: "The file size you uploaded needs to be less than 1mb" })
                }
            } else {
                this.setState({ errorAlert: "The file format you uploaded needs to be a png, jpeg or jpg" })
            }
        }

        // Handle delete File from state and cloudinary
        const handleRemoveFile = e => {
            e.preventDefault()
            axios.post('/selfserve/api/retailer/logo/destroy', {
                image_preview_url
            })
                .then(res => {
                    this.setState({
                        image_preview_url: '',
                        file_name: '',
                        file_public_id: ''
                    })
                })
                .catch(err => {
                    console.log(err);
                    this.setState({ errorAlert: err.response.data.errors })
                })
        }

        // Handle updating the retailer record
        const handleUpdateRetailer = e => {
            e.preventDefault()
            if ((sales_email.includes("@")) && (sales_email.includes("."))) {
                axios.put('/selfserve/api/retailer/update', {
                    RetailerId,
                    retailer_name,
                    display_name,
                    sales_email,
                    image_preview_url,
                    other_pos,
                    email
                })
                    .then(res => {
                        this.props.history.push('/');
                    })
                    .catch(err => {
                        console.log(err)
                        this.setState({ errorAlert: err.response.data.errors })
                    })
            } else {
                this.setState({ errorAlert: "The sales email needs to be in the correct email address format" })
            }
        }

        // Handles cancel button to take user back to the home page
        const handleCancel = e => {
            e.preventDefault()
            this.props.history.push('/')
        }

        // Handles removing the error text from the alert banner onclick "x"
        const handleErrorAlert = e => {
            e.preventDefault()
            this.setState({ errorAlert: '' });
        }

        return (
            <div>
                {isAuth() ? null : <Redirect to='/login' />}
                <div className="container">

                    {/* This is for any alerts/errors */}
                    {(this.state.errorAlert) ?
                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                            {this.state.errorAlert}
                            <button type="button" className="close" onClick={handleErrorAlert} data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        : null}

                    <div className="row mt-3">
                        <div className="col text-center">
                            <h1>
                                Update Retailer
                            </h1>
                            <p>
                                Update your retailer information
                            </p>
                        </div>
                    </div>

                    <form className="row">
                        <div className="col-lg-4">
                            <div className="form-row">
                                <div className="col">
                                    <div className="form-group">
                                        <label>Retailer Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter retailer name"
                                            name="retailer_name"
                                            onChange={handleChange}
                                            value={retailer_name}
                                            autoFocus
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="form-row mt-4">
                                <div className="col">
                                    <div className="form-group">
                                        <label>Retailer Display Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter retailer display name"
                                            name="display_name"
                                            onChange={handleChange}
                                            value={display_name}
                                            required
                                        />
                                        <small>This is the name the public will see</small>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-4">
                            <div className="form-row">
                                <div className="col">
                                    <div className="form-group">
                                        <label>Sales Email</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            placeholder="Enter sales email"
                                            name="sales_email"
                                            onChange={handleChange}
                                            value={sales_email}
                                            required
                                        />
                                        <small>This is how users will contact you</small>
                                    </div>
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="file">Retailer Logo</label>
                                        <div className="file-input-wrapper">
                                            <div className="row">
                                                <div className="col-7 pt-3">
                                                    {file_name ?
                                                        <p className="file-selected-true">{file_name}</p>
                                                        : <p className="file-selected-false">No file selected</p>
                                                    }
                                                </div>
                                                <div className="col-5 pt-2">
                                                    <input
                                                        type="file"
                                                        id="file"
                                                        name="file"
                                                        onChange={handleFileUpload}
                                                        className="file-input-button"
                                                        required
                                                    />
                                                    <label htmlFor="file">Add File</label>
                                                </div>
                                            </div>

                                        </div>
                                        <small>Files maximum 1mb, formats: jpeg, jpg, png</small>
                                    </div>
                                </div>
                            </div>

                            {uploadPercentage > 0 ?
                                <div className="form-row mb-2">
                                    <div className="col">
                                        <div className="progress">
                                            <div className="progress-bar progress-bar-striped bg-info progress-bar-animated"
                                                role="progressbar"
                                                aria-valuenow={uploadPercentage}
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                                style={{ width: `${uploadPercentage}%` }}></div>
                                        </div>
                                    </div>
                                </div>
                                : null}

                            <div className="form-row">
                                <div className="col-lg">
                                    <div className="form-group profile-picture-thumbnail">
                                        {image_preview_url ?
                                            <div>
                                                <img src={image_preview_url} alt="Retailer Logo Thumbnail" />
                                                <button onClick={handleRemoveFile}><span>&times;</span></button>
                                            </div>
                                            : <p>No file selected</p>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-4">
                            <div className="form-row">
                                <div className="col">
                                    <div className="form-group">
                                        <label>Which inventory system do you use?</label>
                                        <select className="custom-select" onChange={handleChange} name="other_pos" value={other_pos} required>
                                            <option disabled value="DEFAULT">Choose...</option>
                                            {Inventory_Systems.map(system => (
                                                <option key={`${system.name}`} value={`${system.name}`}>{`${system.name}`}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>


                            <div className="form-row" style={{ marginTop: "350px" }}>
                                {/* Cancel Button */}
                                <div className="col-lg-6 text-right">
                                    <button
                                        type="submit"
                                        className="canceling-button"
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </button>
                                </div>
                                {/* Update Button */}
                                <div className="col-lg-6 text-right">
                                    <button
                                        type="submit"
                                        className="submit-button"
                                        onClick={handleUpdateRetailer}
                                        disabled=
                                        {retailer_name &&
                                            display_name &&
                                            sales_email &&
                                            image_preview_url &&
                                            other_pos ?
                                            false : true}
                                    >
                                        Update Retailer
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div >
        )
    }
}

export default Editretailer;