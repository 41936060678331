import React from "react";
import "./style.css";

function Retailersummary(props) {
    return (
        <div className="retailer-info row mb-3" key={props.id}>
            <div className="col-lg-4 justify-content-center">
                <img src={props.logo} alt="Retailer Logo" />
            </div>
            <div className="col-lg-8">
                <div className="row">
                    <div className="col">
                        <p>Retailer Name: {props.name}</p>
                        <p>Sales Email: {props.sales_email}</p>
                        <p>Inventory System: {props.other_pos}</p>
                    </div>
                </div>

                <div className="row mt-2">
                    {props.owner ?
                        <div className="col-lg">
                            <a href="/choose-retailer"><button className="submit-button mr-4">Add New Retailer</button></a>
                            <button className="edit mr-4" onClick={() => props.handleEditRetailer(props.id)}>Edit</button>
                            <button className="remove" data-toggle="modal" data-target="#deleteModal" onClick={() => props.handleSelectRetailer(props.id, props.logo)}>Delete</button>
                        </div>
                        :
                        <div className="col-lg">
                            <a href="/choose-retailer"><button className="submit-button mr-4">Add New Retailer</button></a>
                            <button className="remove" data-toggle="modal" data-target="#removeModal" onClick={() => props.handleSelectRetailer(props.id, props.logo)}>Remove</button>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default Retailersummary;