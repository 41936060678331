import React, { Component } from 'react';
import axios from 'axios';

class Reset extends Component {
    constructor() {
        super()
        this.state = {
            password1: '',
            password2: '',
            isPasswordShown: false,
            hasNum: false,
            hasSpecial: false,
            hasMin: false,
            token: '',
            successAlert: '',
            errorAlert: ''
        }
    }

    componentDidMount() {
        // Grab the token from the params of the url. FYI We defined the name "token" in the path in the index.js root file with "/:token". Thats where "token" comes from
        let token = this.props.match.params.token;
        if (token) {
            this.setState({ token: token })
        }
    }

    render() {
        const { password1, password2, token, isPasswordShown, hasNum, hasSpecial, hasMin } = this.state;

        // Toggle Password visibility
        const togglePasswordVisibility = () => {
            const { isPasswordShown } = this.state;
            this.setState({ isPasswordShown: !isPasswordShown })
        }

        // Handle change from inputs
        const handleNewPasswordChange = e => {
            this.setState({ [e.target.name]: e.target.value })

            let containsNumber = e.target.value.match(/\d+/g);
            if (containsNumber !== null) {
                this.setState({ hasNum: true })
            } else {
                this.setState({ hasNum: false })
            }

            let containsSpecial = e.target.value.match(/[\s~`!@#$%&*+=\-\]\\';,/{}|\\":<>()_]/g);
            if (containsSpecial !== null) {
                this.setState({ hasSpecial: true })
            } else {
                this.setState({ hasSpecial: false })
            }

            if (e.target.value.length >= 10) {
                this.setState({ hasMin: true })
            } else {
                this.setState({ hasMin: false })
            }
        }

        // Handle change from inputs
        const handleConfirmPasswordChange = e => {
            this.setState({ [e.target.name]: e.target.value })
        }

        // handle delete success message text
        const handleSuccessAlert = e => {
            e.preventDefault()
            this.setState({ successAlert: '' });
        }

        // handle delete error message text
        const handleErrorAlert = e => {
            e.preventDefault()
            this.setState({ errorAlert: '' });
        }

        const handleSubmit = e => {
            e.preventDefault()
            if ((password1 === password2) && password2 && password1) {
                axios.put('/selfserve/api/auth/password/reset', {
                    newPassword: password1,
                    reset_password_token: token
                }).then(res => {
                    this.setState({
                        password1: '',
                        password2: '',
                        isPasswordShown: false,
                        hasNum: false,
                        hasSpecial: false,
                        hasMin: false,
                        successAlert: res.data.message,
                        errorAlert: ''
                    });
                }).catch((err) => {
                    this.setState({ errorAlert: err.response.data.errors })
                });

            } else {
                this.setState({ errorAlert: 'Passwords don\'t match' })
            }
        };
        return (
            <div>
                <div className="container">

                    {/* This is for success messages */}
                    {(this.state.successAlert) ?
                        <div className="alert alert-success alert-dismissible fade show" role="alert">
                            {this.state.successAlert}
                            <button type="button" className="close" onClick={handleSuccessAlert} data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        : null}

                    {/* This is for any alerts/errors */}
                    {(this.state.errorAlert) ?
                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                            {this.state.errorAlert}
                            <button type="button" className="close" onClick={handleErrorAlert} data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        : null}

                    <div className="row">
                        <div className="col-lg-3 col-md-1"></div>
                        <div className="col-lg-6 col-md-10">
                            <div className="register-form mt-5 p-5">
                                {/* Title w/ Logo */}
                                <div className="row">
                                    <div className="col text-center">
                                        <img src="./../../../images/Brits-Wine-Logo-100w.png" width="50px" className="mt-3 mb-3" alt="Brits Wine Logo" />
                                        <h4 className="mb-3">
                                            Reset Your Password
                                        </h4>
                                    </div>
                                </div>

                                {/* Password form */}
                                <div className="row">
                                    <div className="col-lg-2 col-md-1 col-sm-1"></div>
                                    <form className="col-lg-8 col-md-10 col-sm-10" onSubmit={handleSubmit}>
                                        <div>

                                            {/* New password input */}
                                            <div className="form-row">
                                                <div className="col">
                                                    <div className="form-group">
                                                        <label>New Password</label>
                                                        <input
                                                            type={(isPasswordShown) ? "text" : "password"}
                                                            className="form-control"
                                                            placeholder="Enter new password"
                                                            name="password1"
                                                            onChange={handleNewPasswordChange}
                                                            value={password1}
                                                            maxLength="50"
                                                            autoFocus
                                                            required
                                                        />
                                                        <img className="password-icon"
                                                            src={(isPasswordShown) ? "./../../../images/Eye-Icon-Hide.png" : "./../../../images/Eye-Icon-Show.png"}
                                                            alt="password eye icon"
                                                            width="25px"
                                                            onClick={togglePasswordVisibility}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Password validations */}
                                            <div className="form-row text-center">
                                                <div className="col">
                                                    <small className={hasMin ? "success" : null}>10 characters min</small>
                                                </div>
                                                <div className="col">
                                                    <small className={hasSpecial ? "success" : null}>1 special character</small>
                                                </div>
                                                <div className="col">
                                                    <small className={hasNum ? "success" : null}>1 number</small>
                                                </div>
                                            </div>

                                            <div className="form-row mt-1">
                                                <div className="col text-center">
                                                    {(hasMin && hasSpecial && hasNum) ? <p className="success-complete">Strong password!</p> : null}
                                                </div>
                                            </div>

                                            {/* Confirm password Input */}
                                            <div className="form-row">
                                                <div className="col">
                                                    <div className="form-group">
                                                        <label>Confirm Password</label>
                                                        <input
                                                            type={(isPasswordShown) ? "text" : "password"}
                                                            className="form-control"
                                                            placeholder="Confirm password"
                                                            name="password2"
                                                            onChange={handleConfirmPasswordChange}
                                                            value={password2}
                                                            maxLength="50"
                                                            required
                                                        />
                                                        <img className="password-icon"
                                                            src={(isPasswordShown) ? "./../../../images/Eye-Icon-Hide.png" : "./../../../images/Eye-Icon-Show.png"}
                                                            alt="password eye icon"
                                                            width="25px"
                                                            onClick={togglePasswordVisibility}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-row mt-1">
                                                <div className="col text-center">
                                                    {((hasMin && hasSpecial && hasNum) && (password1 === password2)) ? <p className="success-complete">Passwords match!</p> : null}
                                                </div>
                                            </div>

                                            {/* Reset Password Button */}
                                            <div className="form-row mt-4">
                                                <div className="col text-center">
                                                    <button
                                                        type="submit"
                                                        className="submit-button"
                                                        disabled=
                                                        {(password1 === password2) &&
                                                            password1 &&
                                                            password2 &&
                                                            hasMin &&
                                                            hasSpecial &&
                                                            hasNum ?
                                                            false : true}>
                                                        Reset Password
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <div className="col-lg-2 col-md-1 col-sm-1"></div>
                                </div>
                            </div>
                            <div className="text-center mt-3">
                                <small>Want to login? <a href='/login'>Login!</a></small>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-1"></div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Reset;