import React, { Component } from 'react';
import { isAuth } from '../../helpers/auth';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import product_types from './../../product_types.json';

class Editretailerproduct extends Component {
    constructor() {
        super()
        this.state = {
            email: '',
            errorAlert: '',
            RetailerProductId: '',
            description: '',
            product_id: '',
            product_type: '',
            retailer_id: '',
            title: '',
            image_url: '',
            price: '',
            inventory_quantity: '',
            file_name: '',
            file_public_id: '',
            image_preview_url: '',
            uploadPercentage: 0,
            product_types,
        }
    }

    componentDidMount() {
        if (localStorage.getItem('user')) {
            const user = localStorage.getItem('user');
            const email = JSON.parse(user).email;
            this.setState({ email: email });

            let retailerProductId = this.props.match.params.id;
            if (retailerProductId) {
                this.setState({ retailerProductId: retailerProductId })

                // Grab the retailer data associated with the retailer id from the url params
                axios.post('/selfserve/api/product/find/one', {
                    retailerProductId
                })
                    .then(res => {
                        // Set the input field values to that of the values from the DB record
                        this.setState({
                            description: res.data.retailerProduct.descr,
                            product_id: res.data.retailerProduct.product_id,
                            product_type: res.data.retailerProduct.product_type,
                            retailer_id: res.data.retailerProduct.retailer_id,
                            title: res.data.retailerProduct.title,
                            image_preview_url: res.data.retailerProduct.image_url,
                            price: res.data.retailerProduct.price,
                            inventory_quantity: res.data.retailerProduct.inventory_quantity
                        })
                    })
                    .catch(err => {
                        console.log(err)
                        this.setState({ errorAlert: err.response.data.errors })
                    })
            } else {
                this.setState({ errorAlert: "There is no retailer product id from the url" })
            }
        }
    }


    render() {
        const { retailerProductId, description, product_id, product_type, retailer_id, title, price, inventory_quantity, file_name, image_preview_url, uploadPercentage, product_types } = this.state

        // Handle change from inputs
        const handleChange = e => {
            this.setState({ [e.target.name]: e.target.value })
        }

        // Handle file upload process to cloudinary and state
        const handleFileUpload = e => {
            e.preventDefault()
            console.log(e.target.files[0]);
            let file_type_png = e.target.files[0].type.toLowerCase().includes("png");
            let file_type_jpeg = e.target.files[0].type.toLowerCase().includes("jpeg");
            let file_type_jpg = e.target.files[0].type.toLowerCase().includes("jpg");
            let file_size = e.target.files[0].size;

            if (file_type_png || file_type_jpeg || file_type_jpg) {
                if (file_size <= 1000000) {
                    var file = e.target.files[0];
                    var formData = new FormData();
                    formData.append('file', file);

                    axios({
                        url: '/selfserve/api/product/image/upload',
                        method: 'POST',
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        data: formData,
                        onUploadProgress: (progressEvent) => {
                            let percent = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                            this.setState({ uploadPercentage: percent })
                        }
                    })
                        .then(res => {
                            this.setState({
                                image_preview_url: res.data.image.secure_url,
                                file_name: file.name,
                                file_public_id: res.data.image.public_id,
                                uploadPercentage: 0
                            })
                        })
                        .catch(err => {
                            this.setState({ errorAlert: err.response.data.errors })
                        });
                } else {
                    this.setState({ errorAlert: "The file size you uploaded needs to be less than 1mb" })
                }
            } else {
                this.setState({ errorAlert: "The file format you uploaded needs to be a png, jpeg or jpg" })
            }
        }

        // Handle delete File from state and cloudinary
        const handleRemoveFile = e => {
            e.preventDefault()
            axios.post('/selfserve/api/product/image/destroy', {
                image_preview_url
            })
                .then(res => {
                    this.setState({
                        image_preview_url: '',
                        file_name: '',
                        file_public_id: ''
                    })
                })
                .catch(err => {
                    console.log(err);
                    this.setState({ errorAlert: err.response.data.errors })
                })
        }

        // Handle updating the retailer record
        const handleUpdateRetailer = e => {
            e.preventDefault()
            axios.put('/selfserve/api/product/update', {
                retailerProductId,
                description,
                product_id,
                product_type,
                retailer_id,
                title,
                price,
                inventory_quantity,
                image_preview_url
            })
                .then(res => {
                    this.props.history.push('/');
                })
                .catch(err => {
                    console.log(err)
                    this.setState({ errorAlert: err.response.data.errors })
                })
        }

        // Handles cancel button to take user back to the home page
        const handleCancel = e => {
            e.preventDefault()
            this.props.history.push('/')
        }

        // Handles removing the error text from the alert banner onclick "x"
        const handleErrorAlert = e => {
            e.preventDefault()
            this.setState({ errorAlert: '' });
        }

        return (
            <div>
                {isAuth() ? null : <Redirect to='/login' />}
                <div className="container">

                    {/* This is for any alerts/errors */}
                    {(this.state.errorAlert) ?
                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                            {this.state.errorAlert}
                            <button type="button" className="close" onClick={handleErrorAlert} data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        : null}

                    <div className="row mt-3">
                        <div className="col text-center">
                            <h1>
                                Update Product
                            </h1>
                            <p>
                                Update product information
                            </p>
                        </div>
                    </div>

                    <form className="row">
                        <div className="col-lg-4">
                            <div className="form-row">
                                <div className="col">
                                    <div className="form-group">
                                        <label>Title</label>
                                        <textarea
                                            className="form-control"
                                            rows="3"
                                            placeholder="Enter title"
                                            name="title"
                                            onChange={handleChange}
                                            value={title}
                                            autoFocus
                                            required
                                        ></textarea>
                                        <small>Format: [Producer]+[Wine name and appellation] + [Vintage] + [Wine Color]</small>
                                        <br></br>
                                        <small>Ex. Famille Perrin Châteauneuf-du-Pape Les Sinards 2014 Blanc</small>
                                    </div>
                                </div>
                            </div>

                            <div className="form-row mt-4">
                                <div className="col">
                                    <div className="form-group">
                                        <label>Description</label>
                                        <textarea
                                            className="form-control"
                                            rows="6"
                                            placeholder="Enter description"
                                            name="description"
                                            onChange={handleChange}
                                            value={description}
                                            required
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-4">
                            <div className="form-row">
                                <div className="col">
                                    <div className="form-group">
                                        <label>Product Type</label>
                                        <select className="custom-select" onChange={handleChange} name="product_type" value={product_type} required>
                                            <option disabled value="DEFAULT">Choose...</option>
                                            {product_types.map(product_type => (
                                                <option key={`${product_type.type}`} value={`${product_type.type}`}>{`${product_type.type}`}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="file">Product Image</label>
                                        <div className="file-input-wrapper">
                                            <div className="row">
                                                <div className="col-7 pt-3">
                                                    {file_name ?
                                                        <p className="file-selected-true">{file_name}</p>
                                                        : <p className="file-selected-false">No file selected</p>
                                                    }
                                                </div>
                                                <div className="col-5 pt-2">
                                                    <input
                                                        type="file"
                                                        id="file"
                                                        name="file"
                                                        onChange={handleFileUpload}
                                                        className="file-input-button"
                                                        required
                                                    />
                                                    <label htmlFor="file">Add File</label>
                                                </div>
                                            </div>

                                        </div>
                                        <small>Files maximum 1mb, formats: jpeg, jpg, png</small>
                                    </div>
                                </div>
                            </div>

                            {uploadPercentage > 0 ?
                                <div className="form-row mb-2">
                                    <div className="col">
                                        <div className="progress">
                                            <div className="progress-bar progress-bar-striped bg-info progress-bar-animated"
                                                role="progressbar"
                                                aria-valuenow={uploadPercentage}
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                                style={{ width: `${uploadPercentage}%` }}></div>
                                        </div>
                                    </div>
                                </div>
                                : null}

                            <div className="form-row">
                                <div className="col-lg">
                                    <div className="form-group profile-picture-thumbnail">
                                        {image_preview_url ?
                                            <div>
                                                <img src={image_preview_url} alt="Retailer Logo Thumbnail" />
                                                <button onClick={handleRemoveFile}><span>&times;</span></button>
                                            </div>
                                            : <p>No file selected</p>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-4">
                            <div className="form-row">
                                <div className="col">
                                    <div className="form-group">
                                        <label>Price</label>
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">$</span>
                                            </div>
                                            <input
                                                type="number"
                                                min="1"
                                                step=".01"
                                                className="form-control"
                                                placeholder="Enter price"
                                                name="price"
                                                onChange={handleChange}
                                                value={price}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col">
                                    <div className="form-group">
                                        <label>Inventory Quantity</label>
                                        <input
                                            type="number"
                                            min="1"
                                            step="1"
                                            className="form-control"
                                            placeholder="Enter inventory quantity"
                                            name="inventory_quantity"
                                            onChange={handleChange}
                                            value={inventory_quantity}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-row" style={{ marginTop: "350px" }}>
                                {/* Cancel Button */}
                                <div className="col-lg-6 text-right">
                                    <button
                                        type="submit"
                                        className="canceling-button"
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </button>
                                </div>
                                {/* Update Button */}
                                <div className="col-lg-6 text-right">
                                    <button
                                        type="submit"
                                        className="submit-button"
                                        onClick={handleUpdateRetailer}
                                        disabled=
                                        {retailerProductId &&
                                            description &&
                                            product_id &&
                                            product_type &&
                                            retailer_id &&
                                            title &&
                                            price &&
                                            inventory_quantity &&
                                            image_preview_url ?
                                                false : true}
                                    >
                                        Update Product
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div >
        )
    }
}

export default Editretailerproduct;