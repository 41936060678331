import React, { Component } from 'react';
import axios from 'axios';
import { authenticate, isAuth } from '../../helpers/auth';

class Activate extends Component {
    constructor() {
        super()
        this.state = {
            token: '',
            errorAlert: ''
        }
    }

    componentDidMount() {
        //     let token = match.params.token
        // to decode the token and pull out any user info from the token. Make sure to have the jwt required at the top.
        //     let { first_name } = jwt.decode(token)
        let token = this.props.match.params.token;
        if (token) {
            this.setState({ token: token })
        }
        // Post request to grab token and use token to create new Partner Account record and then login all in one
        axios.post('/selfserve/api/auth/activation', {
            token
        })
            .then(res => {
                authenticate(res, () => {

                })

                // if authenticate redirect to /choose-retailer page
                if (isAuth()) {
                    // if we are authenticated, then remove the email from the session storage. This data was used to display
                    // the email address the verification email was sent on register. It is not the best approach, but it does work.
                    sessionStorage.removeItem("email");
                    // Direct the page to the logged in onboarding process choose retailer page
                    this.props.history.push('/choose-retailer');
                }
            })
            .catch(err => {
                console.log(err)
                this.setState({ errorAlert: err.response.data.errors })
            });
    }

    render() {
        const handleErrorAlert = e => {
            e.preventDefault()
            this.setState({ errorAlert: '' });
        }
        return (
            <div>
                <div className="container">

                    {/* This is for any alerts/errors */}
                    {(this.state.errorAlert) ?
                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                            {this.state.errorAlert}
                            <button type="button" className="close" onClick={handleErrorAlert} data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        : null}

                </div>
            </div>
        )
    }
}

export default Activate;