import React, { Component } from 'react';
// import './style.css';
import { isAuth, signout } from '../../helpers/auth';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import Navbar from './../../components/Navbar/index';
import Footer from './../../components/Footer/index';

class Uploadproducts extends Component {
    constructor() {
        super()
        this.state = {
            first_name: '',
            email: '',
            errorAlert: '',
            uploadPercentage: 0,
            file_name: '',
            retailer: '',
            retailers: []
        }
    }

    componentDidMount() {
        if (localStorage.getItem('user')) {
            const user = localStorage.getItem('user');
            const first_name = JSON.parse(user).first_name;
            const email = JSON.parse(user).email;
            this.setState({
                first_name: first_name,
                email: email
            });

            axios.post('/selfserve/api/retailer/related/find/owned', {
                email
            }).then(res => {
                this.setState({ retailers: res.data.retailers })
            }).catch(err => {
                console.log(err)
                this.setState({ errorAlert: err.response.data.errors })
            })
        }
    }

    render() {
        const { file_name, uploadPercentage, retailers, retailer } = this.state

        // Handles removing error text from alert onclick "x"
        const handleErrorAlert = e => {
            e.preventDefault()
            this.setState({ errorAlert: '' });
        }

        // Handle change from inputs
        const handleChange = e => {
            this.setState({ [e.target.name]: e.target.value })
        }

        // This handles the signout process. Check out the auth.js helper to learn more about how signout works.
        // This just fires the signout function and sne the user to the login page
        const handleSignOut = e => {
            signout(() => {
                this.props.history.push('/login');
            })
        }

        // Handle File upload - see createsommelier page for more info
        const handleFileUpload = e => {
            e.preventDefault()
            console.log(e.target.files[0]);
            let file_type_xlsx = e.target.files[0].type.toLowerCase().includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
            let file_size = e.target.files[0].size;

            if (file_type_xlsx) {
                if (file_size <= 10000000) {
                    var file = e.target.files[0];
                    var formData = new FormData();
                    formData.append('retailer', retailer);
                    formData.append('file', file);

                    axios({
                        url: '/selfserve/api/products/upload',
                        method: 'POST',
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        data: formData,
                        onUploadProgress: (progressEvent) => {
                            let percent = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                            this.setState({ uploadPercentage: percent })
                        }
                    })
                        .then(res => {
                            console.log(res.data)
                            this.setState({
                                file_name: file.name,
                                uploadPercentage: 0
                            })
                            this.props.history.push('/');
                        })
                        .catch(err => {
                            console.log(err)
                            this.setState({ errorAlert: err.response.data.errors })
                        });
                } else {
                    this.setState({ errorAlert: "The file size you uploaded needs to be less than 10mb" })
                }
            } else {
                this.setState({ errorAlert: "The file format you uploaded needs to be a xlsx" })
            }
        }

        return (
            <div>
                {isAuth() ? null : <Redirect to='/login' />}
                <Navbar handleSignOut={handleSignOut}></Navbar>
                <div className="container">

                    {/* This is for any alerts/errors */}
                    {(this.state.errorAlert) ?
                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                            {this.state.errorAlert}
                            <button type="button" className="close" onClick={handleErrorAlert} data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        : null}

                    <div className="row">
                        <div className="col-lg-3"></div>
                        <div className="col-lg-6">
                            <div className="row text-center">
                                <div className="col">
                                    <h4>Upload Products</h4>
                                    <p className="mt-5">In order to add multiple products to your retailer account, please fill out the excel spreadsheet template that you can download below.</p>
                                    <a href="https://res.cloudinary.com/lbok9woke/raw/upload/v1611006535/React%20App/Brits_Wine_Product_Upload_Template_1.xlsx" download>
                                        <button className="submit-button">Download Excel Spreadsheet</button>
                                    </a>
                                    <br></br>
                                    <small>If you have more than 100 products, talk to our <a href="mailto:sales@britswine.com?Subject=Brits%20Wine%20Requesting%20To%20Upload%20More%20Than%20100%20Products">strategic accounts specialist</a></small>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-2"></div>
                                <div className="col-8">
                                    <select className="custom-select" onChange={handleChange} name="retailer" defaultValue={'DEFAULT'} required>
                                        <option disabled value="DEFAULT">Select retailer...</option>
                                        {retailers.map(retailer => (
                                            <option key={`${retailer.id}`} value={`${retailer.id}`}>{`${retailer.name}`}</option>
                                        ))}
                                    </select>
                                    <small>Select the retailer with which you want the uploaded products to be associated from the dropdown.</small>
                                </div>
                                <div className="col-2"></div>
                            </div>

                            {/* File input */}
                            <div className="row text-center mt-5">
                                <div className="col">
                                    <div className="form-group">
                                        <div className="file-input-wrapper">
                                            <div className="row">
                                                <div className="col-7 pt-3">
                                                    {file_name ?
                                                        <p className="file-selected-true">{file_name}</p>
                                                        : <p className="file-selected-false">No file selected</p>
                                                    }
                                                </div>
                                                <div className="col-5 pt-2">
                                                    <input
                                                        type="file"
                                                        id="file"
                                                        name="file"
                                                        disabled={!retailer || file_name}
                                                        onChange={handleFileUpload}
                                                        className={!retailer || file_name ? "file-input-button-disabled" : "file-input-button"}
                                                        required
                                                    />
                                                    <label htmlFor="file">Upload Products</label>
                                                </div>
                                            </div>

                                        </div>
                                        <small>Files maximum 10mb, formats: xlsx</small>
                                    </div>
                                </div>
                            </div>

                            {/* Upload File Progress Bar */}
                            {uploadPercentage > 0 ?
                                <div className="row mt-4 mb-2">
                                    <div className="col">
                                        <div className="progress">
                                            <div className="progress-bar progress-bar-striped bg-info progress-bar-animated"
                                                role="progressbar"
                                                aria-valuenow={uploadPercentage}
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                                style={{ width: `${uploadPercentage}%` }}></div>
                                        </div>
                                    </div>
                                </div>
                                : null}
                        </div>
                        <div className="col-lg-3"></div>
                    </div>
                </div>
                <Footer></Footer>
            </div>
        )
    }
}

export default Uploadproducts;